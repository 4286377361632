import { Country, State, City } from "country-state-city";

const allCountries = Country.getAllCountries();

export const getAllCountries = allCountries.map((country, index) => ({
  label: country.name,
  value: country.name,
  id: country.name + country.isoCode + index,
  ...country
}));

export const getStates = (countryIsoCode) =>
State
  .getStatesOfCountry(countryIsoCode)
  .map((state, index) => ({ label: state.name, value: state.name, id: state.name + state.countryCode + state.stateCode + index, ...state }));

export const getCitiesOfCountry = (countryIsoCode) =>
City
  .getCitiesOfCountry(countryIsoCode)
  .map((city, index) => ({ label: city.name, value: city.name, id: city.name + city.countryCode + city.stateCode, countryName: getCountryByCode(city.countryCode).label, ...city }));

export const getCitiesOfState = (countryIsoCode, stateCode) =>
City
  .getCitiesOfState(countryIsoCode, stateCode)
  .map((city, index) => ({ label: city.name, value: city.name, id: city.name + city.countryCode + city.stateCode + index, ...city }));

const country= (countryCode) => Country.getCountryByCode(countryCode);

export const getCountryByCode = (countryCode) => ({
  label: country(countryCode).name,
  value: country(countryCode).name,
  ...country(countryCode)
});

const allCities = City.getAllCities();
export const getAllCities = allCities.map((city, index) => ({
  label: city.name,
  value: city.name,
  id: city.name + city.countryCode + city.stateCode,
  countryName: getCountryByCode(city.countryCode).label,
  ...city
}));

export const getCity = (countryCode, cityName) => {
  return getCitiesOfCountry(countryCode).filter((city) => city.label === cityName)
};
