import React from "react";
import '../Checkbox/Checkbox.css';
import { FixedSizeList as List } from "react-window";

export const MenuList = (props) => {
  // console.log(props);
  return (
    <List
      {...props}
      height={400}
      style={{height: '100%', maxHeight: 400}}
      itemCount={props.children.length ? props.children.length : 1}
      itemSize={35}
    >
      {({ index, style }) => props.children.length ? <div style={style}>{props.children[index]}</div> : <div style={style}>{props.children}</div>}
    </List>
  );
};
