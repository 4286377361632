import { createSlice } from '@reduxjs/toolkit'

const enterItArticlesSlice = createSlice({
  name: 'enterItArticles',
  initialState: {
    articles: [
      {
        id: 1,
        title: 'Как по модели ISA быстро освоить IT-профессию за три месяца',
        text: 'Уйти в новую сферу будучи взрослым — смелый шаг. Чтобы на него решиться, желание изменить свою жизнь должно быть сильнее страха неизвестности. Путь новичка без опыта не будет простым. Но быстро начать карьеру в IT возможно.',
        dateOfCreate: 1642689174000,
        tags: ['Начало карьеры', 'Обучение', 'Руководство'],
        likes: 10,
        comments: 10,
        img: 'articlePic1.png',
        important: false,
        bookmark: true,
      },
      {
        id: 2,
        title: 'Как начать карьеру QA Automation Engineer: один простой совет',
        text: 'Допустим, вы твердо решили стать специалистом по автоматическому тестированию. Или не твердо. Какие книги читать? Какие курсы лучше? Куда идти после курсов? Послушайте, мой опыт говорит, что можно стартовать без метаний и приобретения знаний, которые не пригодятся.',
        dateOfCreate: 1642689174000,
        tags: ['Начало карьеры', 'QA/ Тестирование', 'Личный опыт'],
        likes: 10,
        comments: 10,
        img: 'articlePic2.png',
        important: false,
        bookmark: false,
      },
      {
        id: 3,
        title: 'Как начать карьеру QA Automation Engineer: один простой совет',
        text: 'Допустим, вы твердо решили стать специалистом по автоматическому тестированию. Или не твердо. Какие книги читать? Какие курсы лучше? Куда идти после курсов? Послушайте, мой опыт говорит, что можно стартовать без метаний и приобретения знаний, которые не пригодятся.',
        dateOfCreate: 1642689174000,
        tags: ['Начало карьеры', 'QA/ Тестирование', 'Личный опыт'],
        likes: 10,
        comments: 10,
        img: 'articlePic1.png',
        important: false,
        bookmark: false,
      },
      {
        id: 4,
        title: 'Зарплата специалистов в IT',
        text: 'Рост ценности IT-шников за последний год шел бурно и скачкообразно. В то же время не совсем понятно, как долго будет сохранятьсятенденция и на что рассчитывать в перспективе. Давайте разбираться, что же творится с оплатой труда на рынке IT-специалистов.',
        dateOfCreate: 1642689174000,
        tags: ['Начало карьеры', 'Карьера', 'HR', 'Руководство'],
        likes: 10,
        comments: 10,
        img: 'articlePic3.png',
        important: true,
        bookmark: true,
      },
      {
        id: 5,
        title: 'Как по модели ISA быстро освоить IT-профессию за три месяца',
        text: 'Уйти в новую сферу будучи взрослым — смелый шаг. Чтобы на него решиться, желание изменить свою жизнь должно быть сильнее страха неизвестности. Путь новичка без опыта не будет простым. Но быстро начать карьеру в IT возможно.',
        dateOfCreate: 1642689174000,
        tags: ['Начало карьеры', 'Обучение', 'Руководство'],
        likes: 10,
        comments: 10,
        img: 'articlePic2.png',
        important: false,
        bookmark: false,
      },
    ],
    totalCount: 59,
    itemsPerPage: 5,
    filters: [
      { id: 1, name: 'last', label: 'Последние', isActive: false },
      { id: 2, name: 'popular', label: 'Популярные', isActive: false },
      { id: 3, name: 'recommended', label: 'Рекомендованные', isActive: false}
    ],
    categories: [
      {
        id: 1, 
        name: 'programming', 
        label: 'Программирование',
        isActive: false,
        subcategories: [
          {id: 1, value: 'backend', label: 'Бекэнд', isActive: false},
          {id: 2, value: 'frontend', label: 'Фронтэнд', isActive: false},
          {id: 3, value: 'databases', label: 'Базы данных', isActive: false}
        ]
      },
      {
        id: 2, 
        name: 'design', 
        label: 'Дизайн',
        isActive: false,
        subcategories: [
          {id: 1, value: 'web-design', label: 'Web Design', isActive: false},
          {id: 2, value: 'mobile-design', label: 'Mobile Design', isActive: false}
        ]
      },
      {
        id: 3, 
        name: 'hr', 
        label: 'HR',
        isActive: false,
        subcategories: [
          {id: 1, value: 'hr-category1', label: 'HR категория1', isActive: false},
          {id: 2, value: 'hr-category2', label: 'HR категория2', isActive: false}
        ]
      },
      {
        id: 4, 
        name: 'analytics', 
        label: 'Аналитика',
        isActive: false,
        subcategories: [
          {id: 1, value: 'analytics-category1', label: 'Аналитика категория1', isActive: false},
          {id: 2, value: 'analytics-category2', label: 'Аналитика категория2', isActive: false}
        ]
      },
      {
        id: 5, 
        name: 'marketing', 
        label: 'Маркетинг',
        isActive: false,
        subcategories: [
          {id: 1, value: 'marketing-category1', label: 'Маркетинг категория1', isActive: false},
          {id: 2, value: 'marketing-category2', label: 'Маркетинг категория2', isActive: false}
        ]
      },
      {
        id: 6, 
        name: 'career', 
        label: 'Карьера',
        isActive: false,
        subcategories: [
          {id: 1, value: 'career-category1', label: 'Карьера категория1', isActive: false},
          {id: 2, value: 'career-category2', label: 'Карьера категория2', isActive: false}
        ]
      }
    ],
    filtersCounter: 0,
    showModal: false,
  },
  reducers: {
    toggleModalWindow(state) {
      state.showModal = !state.showModal
    },
    countActiveFilters(state) {
      state.filtersCounter =
        state.filters.filter(filter => filter.isActive).length +
        state.categories.filter(category => category.isActive).length + 
        state.categories.map(category => 
          category.subcategories.filter(subcategory => 
            subcategory.isActive
          ).length
        ).reduce((prev, curr) => prev + curr)

    },
    setActiveAllFilters(state, action) {
      state.filters.map(filter => filter.isActive = action.payload)
      enterItArticlesSlice.caseReducers.countActiveFilters(state, action)
    },
    setActiveFilter(state, action) {
      const updatedFilter = action.payload

      state.filters = state.filters
                      .map(filter => filter.name === updatedFilter.name
                                     ? { ...filter, ...updatedFilter }
                                     : filter
                       )
      enterItArticlesSlice.caseReducers.countActiveFilters(state, action)
    },
    setActiveAllCategories(state, action) {
      state.categories.map(category => category.isActive = action.payload)
      enterItArticlesSlice.caseReducers.countActiveFilters(state, action)
    },
    setActiveCategory(state, action) {
      const updatedCategory = action.payload

      state.categories = state.categories
                           .map(category => category.name === updatedCategory.name
                                          ? { ...category, ...updatedCategory }
                                          : category
                            )
      enterItArticlesSlice.caseReducers.countActiveFilters(state, action)
    },
    setActiveSubcatecory(state, action) {
      state.categories.flatMap(category => category.subcategories.map(subcategory => subcategory.isActive = false))

      const updatedSubcategories = action.payload

      state.categories = state.categories.map(category => {
        const updtdSubcategories = category.subcategories.map(subcategory => {
          const t = updatedSubcategories.find(sc => sc.value === subcategory.value)
          return t ? t : {...subcategory}
        })
        return {...category, subcategories: updtdSubcategories}
      })

      enterItArticlesSlice.caseReducers.countActiveFilters(state, action)
    },
    setAllFiltersAndCategoriesIsUnactive(state, action) {
      state.filters.map(filter => filter.isActive = false)
      state.categories.map(category => category.isActive = false)
      state.categories.flatMap(category => category.subcategories.map(subcategory => subcategory.isActive = false))
      
      enterItArticlesSlice.caseReducers.countActiveFilters(state, action)
    }
  }
})

export const enterItArticlesReducer = enterItArticlesSlice.reducer;
export const { 
  toggleModalWindow, setActiveAllFilters, setActiveFilter,
  setActiveAllCategories, setActiveCategory, countActiveFilters,
  setAllFiltersAndCategoriesIsUnactive, setActiveSubcatecory
} = enterItArticlesSlice.actions