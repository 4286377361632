import React, {useState} from 'react'
import SearchInput from '../SearchInput/SearchInput'
import {useLocation} from 'react-router-dom'
import styles from './Header.module.css'
import SelectLanguage from "./SelectLanguage/SelectLanguage";
import LinkButton from "./LinkButton/LinkButton";
import LogoComponent from "./LogoComponent/LogoComponent";
import LinkButtonMobile from "./LinkButtonMobile/LinkButtonMobile";
import Sidebar from "../Sidebar/Sidebar";
import {useDispatch, useSelector} from "react-redux";
import {logout, selectIsLoggedIn} from "../../store/reducers/LoginReducer";
import {selectUserData} from "../../store/reducers/UserReducer";

export const languages = [
    {
        value: 'ru',
        label: 'RU',
        countryCode: 'ru'
    },
    {
        value: 'by',
        label: 'BY',
        countryCode: 'by'
    },
    {
        value: 'en',
        label: 'EN',
        countryCode: 'en'
    },
]

function Header() {
    const dispatch = useDispatch()
    const [language, setLanguage] = useState('ru')
    const [openMenu, setOpenMenu] = useState(false)
    const isLoggedIn = useSelector(selectIsLoggedIn)
    const user = useSelector(selectUserData)
    const match = useLocation().pathname
    const handleLogout = () => {
        dispatch(logout())
    }
    return (
        <div className={styles.headerWrapper}>

            <div className={styles.headerContainer}>
                <div className={styles.sidebarContainer} onClick={() => {setOpenMenu(!openMenu)}}>
                    {openMenu && <Sidebar type={'mobile'} openMenu={openMenu} setOpenMenu={setOpenMenu}/>}
                </div>
                {match === '/telegram'
                    ? <div className={styles.headerTitle}>
                        Телеграм каналы
                    </div>
                    : match === '/jobs' || match === '/jobs/vacancies' || match === '/jobs/internships'
                        ? <div className={styles.headerTitle}>
                            Вакансии
                        </div>
                        : <LogoComponent/>}
                <div className={styles.langSignInBlock}>
                    {match !== '/' && <SearchInput/>}
                    <SelectLanguage handleChange={setLanguage} options={languages}/>
                    <div className={styles.loginLogoutBlock}>
                        {isLoggedIn ? <LinkButton handleClick={handleLogout} type={'logout'} title={'Выход'}/> : <LinkButton title={'Вход'}/>}
                        {isLoggedIn && <span>{user.email}</span>}
                    </div>

                </div>
                <LinkButtonMobile/>
            </div>
        </div>
    )
}

export default Header
