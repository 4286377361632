import React from "react";
import { components } from "react-select";
import '../Checkbox/Checkbox.css';
import '../RadioButton/RadioButton.css';

export const Option = (props) => {
  const label = () => {
    return (
      <label>
        {props.data.active ? (
          <>
            <span className="whitespace-pre-wrap">{props.data.notActiveFirst && props.data.notActiveFirst}</span>
            <span className="whitespace-pre-wrap text-dark">{props.data.active}</span>
            <span className="whitespace-pre-wrap">{props.data.notActiveSecond}</span>
          </>) : props.label}
      </label>
    )
  }
  return (
    props.isMulti ? (
      <components.Option {...props}>
      <input
        type="checkbox"
        checked={props.isSelected}
        onChange={() => null}
        className="custom-checkbox"
      />{" "}
      {label()}
    </components.Option>
    ) : (
      <components.Option {...props}>
      <input
        type="radio"
        checked={props.isSelected}
        onChange={() => null}
        className="custom-radio"
      />{" "}
      {label()}
    </components.Option>
    )
  );
};