import {createSlice} from "@reduxjs/toolkit";

export const initialState = {
    userData: {},
};

const UserSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        setUserData(state, action) {
            state.userData = action.payload;
        }
    }
});

export const selectUserData = (state) => state.user.userData

export const userReducer = UserSlice.reducer;
export const {setUserData} = UserSlice.actions;
