import React, { useCallback, useEffect, useState } from 'react'
import Toggle from '../../../common/Toggle/Toggle'
import {
  currencyData,
  profession,
  scopeOfTheCompany,
} from '../../../assets/mock'
import Checkbox from '../../../common/Checkbox/Checkbox'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearCompanySize,
  clearCompanyType,
  clearJobType,
  clearLevel,
  clearSalaryOption,
  clearTypeOfEmployment,
  filterVacancies,
  isClearFiltersReducer,
  removeCompanySize,
  removeCompanyType,
  removeJobType,
  removeLevel,
  removeSalaryOption,
  removeTypeOfEmployment,
  setCities,
  setCompanyScope,
  setCompanySize,
  setCompanyType,
  setCountries,
  setCountriesByCity,
  setCurrency,
  setDirection,
  setJobType,
  setLevel,
  setSalaryFrom,
  setSalaryOption,
  setSalaryTo,
  setSpecialization,
  setTypeOfEmployment,
  getVacancies,
  setChosenJob,
  setFilters,
  clearAllVacancies
} from '../../../store/reducers/VacanciesReducer'
import ReactSelect, { createFilter } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { Option } from '../../../common/Option/Option'
import { searchOption } from '../../../helpers/searchOptions'
import { DropdownIndicator } from '../../../common/DropdownIndicator/DropdownIndicator'
import { inputStylesGeneral, stylesCurrency } from '../../../styles/inputStyles'
import { ClearIndicator } from '../../../common/ClearIndicator/ClearIndicator'
import { MultiValueRemove } from '../../../common/MultiValueRemove/MultiValueRemove'
import {
  getAllCities,
  getAllCountries,
  getCitiesOfCountry,
  getCity,
  getCountryByCode,
} from '../../../helpers/getLocation'
import { MenuList } from '../../../common/MenuList/MenuList'
import { CityOption } from '../../../common/Option/CityOption'
import filterMobile from '../../../assets/filter-mobile.svg'
import filterMobileActive from '../../../assets/filter-mobile-active.svg'
import { clearCheckbox } from '../../../helpers/clearCheckbox'

import { setCurrenciesIntoSessionStorage } from '../../../services/currenciesService'
import convertSalaryToUSD from '../../../helpers/convertSalaryToUSD'
import useDebounce from '../../../helpers/useDebounce'

const Modal = ({ showModal, updateShowModal }) => {
  const {
    direction,
    specialization,
    allVacancies,
    job_type,
    currency,
    level,
    typeOfEmployment,
    companyType,
    countries,
    cities,
    companyScope,
    companySize,
    salaryOptionSelect,
    salaryFrom,
    salaryTo,
    states,
    amount,
    filters,
    lastVacancyID
  } = useSelector((state) => state.vacancies)
  const [specializationState, setSpecializationState] = useState([])
  const [directionState, setDirectionState] = useState(profession)
  const [countriesState, setCountriesState] = useState(getAllCountries)
  const [citiesState, setCitiesState] = useState()
  // const [statesState, setStatesState] = useState([]);
  const [companyScopeState, setCompanyScopeState] = useState(scopeOfTheCompany)
  const dispatch = useDispatch()
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const isHidden = true
  const userAgent = navigator.userAgent.toLowerCase()
  const isSafari = /version/.test(userAgent)
  const [filtersLevel, setFiltersLevel] = useState(
    level ? convertDataCheckbox(level) : []
  )
  const [filtersJobType, setFiltersJobType] = useState(
    job_type ? convertDataCheckbox(job_type) : []
  )
  const [filtersTypeOfEmployment, setFiltersTypeOfEmployment] = useState(
    typeOfEmployment ? convertDataCheckbox(typeOfEmployment) : []
  )
  const [filtersCompanySize, setFiltersCompanySize] = useState(
    companySize ? convertDataCheckbox(companySize) : []
  )
  const [filtersCompanyType, setFiltersCompanyType] = useState(
    companyType ? convertDataCheckbox(companyType) : []
  )

  const salaryOption = [
    { label: 'Почасовая', value: 'hourly' },
    { label: 'За месяц', value: 'perMonth' },
    { label: 'За год', value: 'perYear' },
    // {label: "До вычета налогов", value: "beforeTaxes", checked: false},
    // {label: "На руки", value: "inHand", checked: false},
  ]
  
  const debounceSalaryFrom = useDebounce(salaryFrom, 1000)
  const debounceSalaryTo = useDebounce(salaryTo, 1000)

  const convertData = (data) => {
    const newData = []
    data.forEach((item) => {
      newData.push(item.value)
    })
    return newData
  }

  function convertDataCheckbox(data) {
    let newData = []
    data.forEach((item) => {
      if (item.checked) {
        newData.push(item.value)
      }
    })
    return newData
  }
  const isAllCheckbox = (data) => {
    return data.some((item) => item === 'all')
  }

  const getLocationByIP = useCallback(async () => {
    const responseIP = await fetch(`https://geolocation-db.com/json/`)
    const dataIP = await responseIP.json()
    return dataIP
  }, [])

  const getData = useCallback(async (filters) => {
    console.log(filters);
      dispatch(getVacancies({lastVacancyID: null, filters: filters}))
  }, [])

  // Set currencies rates into sessionStorage
  useEffect(() => {
    (async () => {
      await setCurrenciesIntoSessionStorage()
    })()
  }, [])

  const getFilters = useCallback(async () => {
    const locationIP = ''//await getLocationByIP()
    const city = locationIP.city
    const country = locationIP.country_name
    !cities.length &&
      dispatch(
        setCities(
          getCity(locationIP.country_code, locationIP.city)
        )
      )
    !countries.length &&
      dispatch(setCountriesByCity(getCountryByCode(locationIP.country_code)))

    const filters = {
      limit: 20,
      direction: direction ? direction.value : '',
      specialization: specialization ? convertData(specialization) : [''],
      // programmingLanguage: [''],
      // technologies: [''],
      level: level
        ? isAllCheckbox(convertDataCheckbox(level))
          ? ['']
          : convertDataCheckbox(level)
        : [''],
      country: countries.length ? convertData(countries) : [country],
      city: cities.length ? convertData(cities) : [city],
      //state: states ? convertData(states) : [''],
      salary: [
        convertSalaryToUSD(currency.value, +salaryFrom),
        convertSalaryToUSD(currency.value, +salaryTo),
      ],
      //currency: currency ? currency.value : '',
	  salaryOption: salaryFrom !== '' ? salaryOptionSelect : '',
      // companyScope: companyScope ? convertData(companyScope) : [],       {/* скрыто, т.к на бэке еще не реализовано */}
      // typeOfEmployment: typeOfEmployment ? (isAllCheckbox(convertDataCheckbox(typeOfEmployment)) ? [''] : convertDataCheckbox(typeOfEmployment)) : [''],
      // companyType: companyType ? (isAllCheckbox(convertDataCheckbox(companyType)) ? [''] : convertDataCheckbox(companyType)) : [''],
      // companySize: companySize ? convertDataCheckbox(companySize) : [''],
      job_type: job_type ? convertDataCheckbox(job_type) : [''],
    }
    if (showModal) {
      dispatch(clearAllVacancies())
      dispatch(setChosenJob({}))
      dispatch(setFilters(filters))
      getData({...filters, id: lastVacancyID})
    }
  }, [])

  useEffect(() => {
    const filters = {
      limit: 20,
      direction: direction ? direction.value : '',
      specialization: specialization ? convertData(specialization) : [''],
      level: level
        ? isAllCheckbox(convertDataCheckbox(level))
          ? ['']
          : convertDataCheckbox(level)
        : [''],
      country: countries.length ? convertData(countries) : [''],
      city: cities.length ? convertData(cities) : [''],
      salary: [
        convertSalaryToUSD(currency.value, +debounceSalaryFrom),
        convertSalaryToUSD(currency.value, +debounceSalaryTo),
      ],
      salaryOption: salaryFrom !== '' ? salaryOptionSelect : '',
      job_type: job_type ? convertDataCheckbox(job_type) : [''],
    }
    if (showModal) {
      dispatch(clearAllVacancies())
      dispatch(setChosenJob({}))
      dispatch(setFilters(filters))
      getData({...filters, id: lastVacancyID})
    }
  }, [currency, showModal, debounceSalaryFrom, debounceSalaryTo, salaryOptionSelect])

  useEffect(() => {
    getFilters()
  }, [getFilters])

  const onChangeSelect = (filter, action, value) => {
    dispatch(clearAllVacancies())
    dispatch(setChosenJob({}))
    dispatch(action)
    let updateFilters = { ...filters }
    updateFilters[filter] = value
    getData({...updateFilters, id: lastVacancyID})
    dispatch(setFilters(updateFilters))
  }

  const setCitiesAndCountries = (
    o,
    country,
    filterCity,
    filterCountry,
    actionCity,
    actionCountry
  ) => {
    dispatch(actionCity)
    dispatch(actionCountry)
    let updateFilters = { ...filters }
    updateFilters[filterCity] = convertData(o)
    updateFilters[filterCountry] = convertData(country)
    getData({...updateFilters, id: lastVacancyID})
    dispatch(setFilters(updateFilters))
  }

  const onChangeSelectCitiesAndCountries = (o) => {
    const countries = []
    o.forEach((city) => {
      if (!countries.length) {
        countries.push(getCountryByCode(city.countryCode))
      }
      countries.forEach((country) => {
        if (country.value !== city.countryName) {
          countries.push(getCountryByCode(city.countryCode))
        }
      })
    })
    setCitiesAndCountries(
      o,
      countries,
      'city',
      'country',
      setCities(o),
      setCountriesByCity(countries)
    )
  }

  const getSpecialization = useCallback(
    (direction) => {
      if (direction) {
        const filterProfession = profession.filter((option) => {
          return option.value === direction.value
        })

        if (filterProfession.length) {
          return filterProfession[0].specialization
        }
      } else {
        dispatch(setSpecialization(['']))
      }
    },
    [dispatch]
  )

  useEffect(() => {
    if (direction) {
      specialization.forEach((spec) => {
        if (!direction.specialization.includes(spec)) {
          dispatch(setSpecialization(['']))
        }
      })
    }
  }, [direction, dispatch])

  const citiesOptions = useCallback(() => {
    let cities = []
    if (countries.length) {
      countries.map((country) =>
        cities.push(...getCitiesOfCountry(country.isoCode))
      )
    } else {
      cities = getAllCities
    }
    return cities.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    )
  }, [countries])

  useEffect(() => {
    setCitiesState(citiesOptions())
  }, [dispatch, citiesOptions])

  // useEffect(() => {
  // 	setStatesState(statesOptions());
  // }, [dispatch, statesOptions]);

  const onInputChange = (value) => {
    if (value.length >= 3) {
      setCitiesState(searchOption(citiesOptions(), value))
      setMenuIsOpen(true)
    } else {
      setCitiesState(citiesOptions())
      setMenuIsOpen(false)
    }
  }

  const setCheckbox = (name, action, value, filters, setState) => {
    const updateFilters = [...filters, value]
    onChangeSelect(name, action, updateFilters)
    setState(updateFilters)
  }

  const removeCheckbox = (name, action, value, filters, setState) => {
    const updateFilters = [...filters].filter((item) => item !== value)
    onChangeSelect(name, action, updateFilters)
    setState(updateFilters)
  }

  const clearFilters = () => {
    dispatch(setDirection(null))
    dispatch(setSpecialization([]))
    dispatch(setCountries([]))
    dispatch(setCities([]))
    // dispatch(setCompanyScope([]));
    dispatch(clearLevel(clearCheckbox(level)))
    dispatch(clearJobType(clearCheckbox(job_type)))
    dispatch(setSalaryOption('perMonth'))
    // dispatch(clearTypeOfEmployment(clearCheckbox(typeOfEmployment)));
    // dispatch(clearCompanySize(clearCheckbox(companySize)));
    // dispatch(clearCompanyType(clearCheckbox(companyType)));
    dispatch(setSalaryFrom(''))
    dispatch(setSalaryTo(''))
    dispatch(setCurrency({ value: 'USD', label: 'USD' }))
    dispatch(isClearFiltersReducer(true))
    const filters = {
      direction: '',
      specialization: [],
      // programmingLanguage: [],
      // technologies: [],
      level: [],
      country: [],
      city: [],
      // state: [],
      salary: [],
      //currency: '',
      salaryOption: '',
      // companyScope: [],        {/* скрыто, т.к на бэке еще не реализовано */}
      // typeOfEmployment: [],
      // companyType: [],
      // companySize: companySize ? convertDataCheckbox(companySize) : [''],
      job_type: [],
    }
    dispatch(setFilters(null))
  }

  const salaryFromInputOnChangeHandler = (event) => {
    dispatch(setSalaryFrom(event.target.value))
  }

  const salaryToInputOnChangeHandler = (event) => {
    dispatch(setSalaryTo(event.target.value))
  }

  const salaryOptionCheckBoxesHandler = (value) => {
	  dispatch(setSalaryOption(value))
  }

  return (
    <>
      <button
        onClick={() => updateShowModal(!showModal)}
        type="button"
        className={`z-40
				sticky
				bottom-0
				right-0
				pl-14
				pr-6
				w-1/8
				text-buttonblue
			
				rounded
				border
				h-[44px]
				${showModal ? 'bg-cross' : 'bg-filter'} bg-no-repeat bg-leftm bg-white
				hover:shadow-lg

				transition
				duration-150
				ease-in-out
				sm:hidden`}
      >
        Фильтры
      </button>
      <button
        onClick={() => updateShowModal(!showModal)}
        type="button"
        className={`z-40
				sticky
				bottom-0
				right-0
				h-[44px]
				hover:pointer
				transition
				duration-150
				ease-in-out
				hidden
        sm:h-[63px] sm:w-[63px] sm:flex sm:justify-center sm:items-center
        `}
      >
        <img
        className='h-auto w-[30px]'
          src={!showModal ? filterMobile : filterMobileActive}
          alt="Фильтр"
        />
      </button>
      {showModal ? (
        <>
          <div className="no-scrollbar flex justify-end backdrop-blur-sm overflow-y-auto mt-[5%] fixed inset-0 z-30 outline-none focus:outline-none sm:mt-[15%]">
            <div className="relative w-3/4 max-w-6xl sm:w-full h-fit">
              <div className="gap-y-5 px-10 py-12 border-0 rounded-l-3xl shadow-lg shadow-filters flex flex-col justify-between w-full bg-white outline-none focus:outline-none min-h-full sm:px-4 sm:pt-4 sm:items-start">
                <div className="flex items-center justify-start w-full">
                  <button
                    className={`bg-buttonblue text-white text-sm font-semibold rounded-md border w-[264px] h-[41px] md:w-[343px] md:h-[44px] md:z-50 ${
                      showModal && 'md:hidden'
                    } md:fixed md:bottom-0`}
                    onClick={() => updateShowModal(false)}
                  >
                    Показать все {amount} вакансий
                  </button>
                  <button
                    className="bg-transparent border-0 text-buttonblue text-sm font-semibold pl-7"
                    onClick={clearFilters}
                  >
                    Очистить
                  </button>
                </div>
                <div className="flex justify-start gap-x-10 gap-y-5 w-full flex-wrap sm:px-4">
                  <div className="select">
                    <div className="block text-sm font-semibold text-gray dark:text-gray mb-2.5">
                      Желаемая работа
                    </div>
                    <CreatableSelect
                      styles={inputStylesGeneral}
                      options={directionState}
                      onInputChange={(value) =>
                        value
                          ? setDirectionState(searchOption(profession, value))
                          : setDirectionState(profession)
                      }
                      hideSelectedOptions={false}
                      components={{
                        Option: Option,
                        DropdownIndicator: DropdownIndicator,
                        ClearIndicator: ClearIndicator,
                      }}
                      onChange={(o) => {
                        setSpecializationState(getSpecialization(o))
                        dispatch(setDirection(o))
                        let updateFilters = { ...filters }
                        updateFilters.direction = o ? o.value : ''
                        updateFilters.specialization = []
                        getData({...updateFilters, id: lastVacancyID})
                        setFilters(updateFilters)
                      }}
                      isClearable={true}
                      name="direction"
                      value={direction}
                      placeholder={null}
                      noOptionsMessage={() => 'Нет опций'}
                      maxMenuHeight={800}
                      formatCreateLabel={(inputValue) => inputValue}
                    />
                  </div>
                  <div className="select">
                    <div className="block text-sm font-semibold text-gray dark:text-gray mb-2.5">
                      Специализация
                    </div>
                    <ReactSelect
                      styles={inputStylesGeneral}
                      options={specializationState}
                      isMulti
                      onInputChange={(value) =>
                        value
                          ? setSpecializationState(
                              searchOption(getSpecialization(direction), value)
                            )
                          : setSpecializationState(getSpecialization(direction))
                      }
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      components={{
                        Option: Option,
                        DropdownIndicator: DropdownIndicator,
                        ClearIndicator: ClearIndicator,
                        MultiValueRemove: MultiValueRemove,
                      }}
                      noOptionsMessage={() => 'Нет опций'}
                      onChange={(o) => {
                        onChangeSelect(
                          'specialization',
                          setSpecialization(o),
                          convertData(o)
                        )
                      }}
                      value={specialization}
                      placeholder={null}
                      maxMenuHeight={800}
                      isSearchable={direction ? true : false}
                      blurInputOnSelect={false}
                    />
                  </div>
                  <div className="select">
                    <div className="block text-sm font-semibold text-gray dark:text-gray mb-2.5">
                      Страна
                    </div>
                    <ReactSelect
                      styles={inputStylesGeneral}
                      options={countriesState}
                      isMulti
                      onInputChange={(value) =>
                        value
                          ? setCountriesState(
                              searchOption(getAllCountries, value)
                            )
                          : setCountriesState(getAllCountries)
                      }
                      hideSelectedOptions={false}
                      closeMenuOnSelect={false}
                      components={{
                        Option: Option,
                        DropdownIndicator: DropdownIndicator,
                        ClearIndicator: ClearIndicator,
                        MultiValueRemove: MultiValueRemove,
                      }}
                      onChange={(o) => {
                        onChangeSelect(
                          'country',
                          setCountries(o),
                          convertData(o)
                        )
                      }}
                      value={countries}
                      placeholder="Все страны"
                      noOptionsMessage={() => 'Нет опций'}
                      blurInputOnSelect={false}
                    />
                  </div>
                  {/* {<div className="select">
										<div className="block text-sm font-semibold text-gray dark:text-gray mb-2.5">Штат</div>
										<ReactSelect
											styles={inputStylesGeneral}
											options={statesState}
											isMulti
											onInputChange={(value) => value ? setStatesState(searchOption(statesOptions(), value)) : setStatesState(statesOptions())}
											hideSelectedOptions={false}
											closeMenuOnSelect={false}
											components={{Option: Option, DropdownIndicator: DropdownIndicator, ClearIndicator: ClearIndicator, MultiValueRemove: MultiValueRemove}}
											onChange={(o) => {
												dispatch(setStates(o));
											}}
											name="states"
											value={states}
											placeholder="Все штаты"
											noOptionsMessage={() => 'Нет опций'}
											maxMenuHeight={800}
										/>
									</div>} */}
                  <div className="select">
                    <div className="block text-sm font-semibold text-gray dark:text-gray mb-2.5">
                      Город
                    </div>
                    {countries.length ? (
                      <ReactSelect
                        styles={inputStylesGeneral}
                        options={citiesState}
                        isMulti
                        onInputChange={(value) =>
                          value
                            ? setCitiesState(
                                searchOption(citiesOptions(), value)
                              )
                            : setCitiesState(citiesOptions())
                        }
                        hideSelectedOptions={false}
                        closeMenuOnSelect={false}
                        components={{
                          Option: CityOption,
                          DropdownIndicator: DropdownIndicator,
                          ClearIndicator: ClearIndicator,
                          MultiValueRemove: MultiValueRemove,
                          MenuList: MenuList,
                        }}
                        onChange={(o) => {
                          onChangeSelect('city', setCities(o), convertData(o))
                        }}
                        name="cities"
                        value={cities}
                        placeholder="Все города"
                        noOptionsMessage={() => 'Нет опций'}
                        getOptionValue={(option) => option.id}
                        filterOption={createFilter({ ignoreAccents: false })}
                        captureMenuScroll={false}
                        maxMenuHeight={800}
                        minMenuHeight={140}
                        blurInputOnSelect={false}
                      />
                    ) : (
                      <ReactSelect
                        styles={inputStylesGeneral}
                        options={citiesState}
                        isMulti
                        onInputChange={(value) => onInputChange(value)}
                        hideSelectedOptions={false}
                        closeMenuOnSelect={false}
                        components={{
                          Option: CityOption,
                          DropdownIndicator: DropdownIndicator,
                          ClearIndicator: ClearIndicator,
                          MultiValueRemove: MultiValueRemove,
                          MenuList: MenuList,
                        }}
                        onChange={(o) => {
                          onChangeSelectCitiesAndCountries(o)
                        }}
                        name="cities"
                        value={cities}
                        placeholder="Все города"
                        noOptionsMessage={() => 'Нет опций'}
                        getOptionValue={(option) => option.id}
                        filterOption={createFilter({ ignoreAccents: false })}
                        captureMenuScroll={false}
                        menuIsOpen={menuIsOpen}
                        maxMenuHeight={800}
                        minMenuHeight={140}
                        blurInputOnSelect={false}
                      />
                    )}
                  </div>
                  {!isHidden && (
                    <div className="select">
                      {' '}
                      {/* скрыто, т.к на бэке еще не реализовано */}
                      <div className="block text-sm font-semibold text-gray dark:text-gray mb-2.5">
                        Сфера деятельности компании
                      </div>
                      <ReactSelect
                        styles={inputStylesGeneral}
                        options={companyScopeState}
                        isMulti
                        onInputChange={(value) =>
                          value
                            ? setCompanyScopeState(
                                searchOption(scopeOfTheCompany, value)
                              )
                            : setCompanyScopeState(scopeOfTheCompany)
                        }
                        hideSelectedOptions={false}
                        closeMenuOnSelect={false}
                        components={{
                          Option: Option,
                          DropdownIndicator: DropdownIndicator,
                          ClearIndicator: ClearIndicator,
                          MultiValueRemove: MultiValueRemove,
                        }}
                        onChange={(o) =>
                          onChangeSelect(
                            'companyScope',
                            setCompanyScope(o),
                            convertData(o)
                          )
                        }
                        name="scope"
                        value={companyScope}
                        placeholder="Все сферы"
                        noOptionsMessage={() => 'Нет опций'}
                        maxMenuHeight={800}
                        blurInputOnSelect={false}
                      />
                    </div>
                  )}
                </div>
                <div className="flex flex-wrap gap-y-5 gap-x-20 justify-start sm:flex-col sm:px-4">
                  <div className="qualification">
                    <h2 className="text-gray mb-5 font-semibold text-sm">
                      Квалификация:
                    </h2>
                    <div className="flex sm:flex-col">
                      <div className="mr-3">
                        {level.map((option, index) => {
                          if (index <= 3) {
                            return (
                              <Checkbox
                                key={index}
                                id={index + 'level'}
                                label={option.label}
                                value={option.value}
                                setValue={(value) =>
                                  setCheckbox(
                                    'level',
                                    setLevel(value),
                                    value,
                                    filtersLevel,
                                    setFiltersLevel
                                  )
                                }
                                removeValue={(value) =>
                                  removeCheckbox(
                                    'level',
                                    removeLevel(value),
                                    value,
                                    filtersLevel,
                                    setFiltersLevel
                                  )
                                }
                                checked={option.checked}
                              />
                            )
                          }
                        })}
                      </div>
                      <div>
                        {level.map((option, index) => {
                          if (index > 3) {
                            return (
                              <Checkbox
                                key={index}
                                id={index + 'level'}
                                label={option.label}
                                value={option.value}
                                setValue={(value) =>
                                  setCheckbox(
                                    'level',
                                    setLevel(value),
                                    value,
                                    filtersLevel,
                                    setFiltersLevel
                                  )
                                }
                                removeValue={(value) =>
                                  removeCheckbox(
                                    'level',
                                    removeLevel(value),
                                    value,
                                    filtersLevel,
                                    setFiltersLevel
                                  )
                                }
                                checked={option.checked}
                              />
                            )
                          }
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="workingHours">
                    <h2 className="text-gray mb-5 font-semibold text-sm">
                      Условия работы:
                    </h2>
                    {job_type.map((option, index) => {
                      return (
                        <Checkbox
                          key={index}
                          id={index + 'job_type'}
                          label={option.label}
                          value={option.value}
                          setValue={(value) =>
                            setCheckbox(
                              'job_type',
                              setJobType(value),
                              value,
                              filtersJobType,
                              setFiltersJobType
                            )
                          }
                          removeValue={(value) =>
                            removeCheckbox(
                              'job_type',
                              removeJobType(value),
                              value,
                              filtersJobType,
                              setFiltersJobType
                            )
                          }
                          checked={option.checked}
                        />
                      )
                    })}
                  </div>

                  <div className="salary w-1/3 xl:w-full pr-1">
                    <h2 className="text-gray mb-5 font-semibold text-sm">
                      Заработная плата:
                    </h2>
                    <div className="flex gap-x-2.5 mb-2.5">
                      <div className="flex items-center">
                        <label className="text-gray font-semibold text-sm">
                          От
                        </label>
                        <input
                          type="text"
                          pattern="[0-9]+"
                          className={`w-16 border border-lightGray rounded border-solid ml-1 h-7 outline-none px-2.5 ${
                            isSafari
                              ? 'text-base focus:text-base'
                              : 'text-sm focus:text-sm'
                          }`}
                          value={salaryFrom}
                          onChange={salaryFromInputOnChangeHandler}
                        />
                      </div>
                      <div className="flex items-center">
                        <label className="text-gray font-semibold text-sm">
                          До
                        </label>
                        <input
                          type="text"
                          pattern="[0-9]+"
                          className={`w-16 border border-lightGray rounded border-solid ml-1 h-7 outline-none px-2.5 ${
                            isSafari
                              ? 'text-base focus:text-base'
                              : 'text-sm focus:text-sm'
                          }`}
                          value={salaryTo}
                          onChange={salaryToInputOnChangeHandler}
                        />
                      </div>
                      <ReactSelect
                        styles={stylesCurrency}
                        options={currencyData}
                        onChange={(o) => {
                          dispatch(setCurrency(o))
                        }}
                        name="currency"
                        value={currency}
                        placeholder={null}
                        noOptionsMessage={() => 'Нет опций'}
                        isSearchable={false}
                        components={{ DropdownIndicator: DropdownIndicator }}
                        className="xl:self-end"
                      />
                    </div>
                    <div className="flex flex-wrap gap-x-4 xl:gap-y-2 xl:flex-col">
                      {salaryOption.map((option, index) => {
                        return (
                          <Checkbox
                            key={index}
                            id={index + 'salaryOption'}
                            label={option.label}
                            value={option.value}
                            setValue={salaryOptionCheckBoxesHandler}
                            checked={salaryOptionSelect === option.value}
                            name="salaryOption"
                          />
                        )
                      })}
                    </div>
                  </div>

                  {!isHidden && (
                    <div>
                      {' '}
                      {/* скрыто, т.к на бэке еще не реализовано */}
                      <h2 className="text-gray mb-5 font-semibold text-sm">
                        Тип занятости:
                      </h2>
                      <div className="flex sm:flex-col">
                        <div className="mr-3">
                          {typeOfEmployment.map((option, index) => {
                            if (index <= 3) {
                              return (
                                <Checkbox
                                  key={index}
                                  id={index + 'typeOfEmployment'}
                                  label={option.label}
                                  value={option.value}
                                  setValue={(value) =>
                                    setCheckbox(
                                      'typeOfEmployment',
                                      setTypeOfEmployment(value),
                                      value,
                                      filtersTypeOfEmployment,
                                      setFiltersTypeOfEmployment
                                    )
                                  }
                                  removeValue={(value) =>
                                    removeCheckbox(
                                      'typeOfEmployment',
                                      removeTypeOfEmployment(value),
                                      value,
                                      filtersTypeOfEmployment,
                                      setFiltersTypeOfEmployment
                                    )
                                  }
                                  checked={option.checked}
                                />
                              )
                            }
                          })}
                        </div>
                        <div>
                          {typeOfEmployment.map((option, index) => {
                            if (index > 3) {
                              return (
                                <Checkbox
                                  key={index}
                                  id={index + 'typeOfEmployment'}
                                  label={option.label}
                                  value={option.value}
                                  setValue={(value) =>
                                    setCheckbox(
                                      'typeOfEmployment',
                                      setTypeOfEmployment(value),
                                      value,
                                      filtersTypeOfEmployment,
                                      setFiltersTypeOfEmployment
                                    )
                                  }
                                  removeValue={(value) =>
                                    removeCheckbox(
                                      'typeOfEmployment',
                                      removeTypeOfEmployment(value),
                                      value,
                                      filtersTypeOfEmployment,
                                      setFiltersTypeOfEmployment
                                    )
                                  }
                                  checked={option.checked}
                                />
                              )
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  )}

                  {!isHidden && (
                    <div className="companySize">
                      {' '}
                      {/* скрыто, т.к на бэке еще не реализовано */}
                      <h2 className="text-gray mb-5 font-semibold text-sm">
                        Размер компании:
                      </h2>
                      {companySize.map((option, index) => {
                        return (
                          <Checkbox
                            key={index}
                            id={index + 'companySize'}
                            label={option.label}
                            value={option.value}
                            setValue={(value) =>
                              setCheckbox(
                                'companySize',
                                setCompanySize(value),
                                value,
                                filtersCompanySize,
                                setFiltersCompanySize
                              )
                            }
                            removeValue={(value) =>
                              removeCheckbox(
                                'companySize',
                                removeCompanySize(value),
                                value,
                                filtersCompanySize,
                                setFiltersCompanySize
                              )
                            }
                            checked={option.checked}
                          />
                        )
                      })}
                    </div>
                  )}

                  {!isHidden && (
                    <div className="companyType">
                      {' '}
                      {/* скрыто, т.к на бэке еще не реализовано */}
                      <h2 className="text-gray mb-5 font-semibold text-sm">
                        Тип компании:
                      </h2>
                      <div className="flex sm:flex-col">
                        <div className="mr-3">
                          {companyType.map((option, index) => {
                            if (index <= 2) {
                              return (
                                <Checkbox
                                  key={index}
                                  id={index + 'companyType'}
                                  label={option.label}
                                  value={option.value}
                                  setValue={(value) =>
                                    setCheckbox(
                                      'companyType',
                                      setCompanyType(value),
                                      value,
                                      filtersCompanyType,
                                      setFiltersCompanyType
                                    )
                                  }
                                  removeValue={(value) =>
                                    removeCheckbox(
                                      'companyType',
                                      removeCompanyType(value),
                                      value,
                                      filtersCompanyType,
                                      setFiltersCompanyType
                                    )
                                  }
                                  checked={option.checked}
                                />
                              )
                            }
                          })}
                        </div>
                        <div>
                          {companyType.map((option, index) => {
                            if (index > 2 && index < companyType.length - 1) {
                              return (
                                <Checkbox
                                  key={index}
                                  id={index + 'companyType'}
                                  label={option.label}
                                  value={option.value}
                                  setValue={(value) =>
                                    setCheckbox(
                                      'companyType',
                                      setCompanyType(value),
                                      value,
                                      filtersCompanyType,
                                      setFiltersCompanyType
                                    )
                                  }
                                  removeValue={(value) =>
                                    removeCheckbox(
                                      'companyType',
                                      removeCompanyType(value),
                                      value,
                                      filtersCompanyType,
                                      setFiltersCompanyType
                                    )
                                  }
                                  checked={option.checked}
                                />
                              )
                            }
                          })}
                        </div>
                      </div>
                      <Checkbox
                        id={companyType.length - 1 + 'companyType'}
                        label={companyType[companyType.length - 1].label}
                        value={companyType[companyType.length - 1].value}
                        setValue={(value) =>
                          setCheckbox(
                            'companyType',
                            setCompanyType(value),
                            value,
                            filtersCompanyType,
                            setFiltersCompanyType
                          )
                        }
                        removeValue={(value) =>
                          removeCheckbox(
                            'companyType',
                            removeCompanyType(value),
                            value,
                            filtersCompanyType,
                            setFiltersCompanyType
                          )
                        }
                        checked={companyType[companyType.length - 1].checked}
                      />
                    </div>
                  )}
                </div>

                {!isHidden && (
                  <div className="border-t border-[#BFD4FF] w-full">
                    <Toggle title="Подписаться на уведомления по данным критериям" />
                    <Toggle title="Скрывать просмотренные вакансии" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  )
}

export default Modal
