import React, {useState} from 'react';
import arrow from "../../../assets/arrow.svg";
import styles from './SelectLanguage.module.css'

function SelectLanguage({handleChange, options}) {
    const [selectedOption, setSelectedOption] = useState('RU');
    const [showOptions, setShowOptions] = useState(false);

    const handleSelect = (value, label) => {
        setSelectedOption(label);
        setShowOptions(false);
        handleChange(value)
    };

    return (
        <div className={styles.selectLanguage}>
            <div className={styles.selectBox} onClick={() => setShowOptions(!showOptions)}>
                {selectedOption}
                <img className={styles.arrow} src={arrow} alt="Language"/>

            </div>
            <div className={`${styles.options} ${showOptions ? styles.optionsShow : ''}`}>
                {options.map((option) => (
                    <div key={option.value} className={styles.option} onClick={() => handleSelect(option.value, option.label)}>
                        {option.label}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default SelectLanguage;