import axios from "axios";

export const API_URL = `https://4dev.itcoty.ru/api/v1`;
export const $api = axios.create({
    withCredentials: true,
    baseURL: API_URL,
});

$api.interceptors.request.use((config) => {
    const token = localStorage.getItem("access_token");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
});

$api.interceptors.response.use(
    (config) => {
        return config;
    },
    async (error) => {
        const originalRequest = error.config;
        if (
            error.response.status === 401 &&
            error.config &&
            !error.config._isRetry
        ) {
            originalRequest._isRetry = true;
            try {
                const token = localStorage.getItem('refresh_token');

                const response = await axios.post(`${API_URL}/token/refresh/`, {refresh: token}, {
                    withCredentials: true,
                });
                localStorage.setItem("access_token", response.data.access);
                return $api.request(originalRequest);
            } catch (e) {
                console.log("Пользователь не авторизован");
            }
        }
        throw error;
    }
);
export const instance = axios.create({
    baseURL: API_URL,
    withCredentials: true,
})


