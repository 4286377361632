import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import styles from "./SocialLoginOptions.module.css";
import mailru from "../../../assets/mailru.png";
import mail from "../../../assets/mail.png";
import google from "../../../assets/google.png";
import { useDispatch } from "react-redux";
import { login } from "../../../store/reducers/LoginReducer";
import { registration } from "../../../store/reducers/RegistrationReducer";

export const SocialLoginOptions = ({ isLoginPage }) => {
  const dispatch = useDispatch();
  const googleLogin = useGoogleLogin({

    onSuccess: async (tokenResponse) => {
      try {
        const userInfoResponse = await fetch(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`,
            },
          }
        );
        const userInfo = await userInfoResponse.json();

        if (isLoginPage) {
          const reqData = {
            email: userInfo.email,
            password: `Google${userInfo.sub}`,
          };
          dispatch(login(reqData));
        } else {
          const reqData = {
            email: userInfo.email,
            password: `Google${userInfo.sub}`,
            confirmPassword: `Google${userInfo.sub}`,
          };
          dispatch(registration(reqData));
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    },
    onError: () => {
      console.log("Login Failed");
    },
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.headerBlock}>
        <span className={styles.line}></span>
        <span className={styles.title}>или войти через</span>
        <span className={styles.line}></span>
      </div>
      <div className={styles.imagesBlock}>
        <a href="/*" title={"Mail.ru"}>
          <img src={mailru} alt="Mail.ru" />
        </a>
        <a href="/*" title={"Mail"}>
          <img src={mail} alt="Mail.ru" />
        </a>
        <div
          onClick={() => googleLogin()}
          style={{ cursor: "pointer" }}
          title={"GMail"}
        >
          <img src={google} alt="Gmail" />
        </div>
      </div>
    </div>
  );
};
