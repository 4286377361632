import React from 'react';
import styles from './LinkWithArrow.module.css';
import arrow from "../../assets/arrow.svg";
import {Link} from "react-router-dom";

const LinkWithArrow = ({path, title}) => {
    return (
        <Link className={styles.link} to={path}>
            <span className={styles.linkSpan}>{title}</span>
            <img className={styles.linkArrow} src={arrow} alt="arrow"/>
        </Link>
    );
};

export default LinkWithArrow;