import React from 'react';
import styles from './LinkButton.module.css'
import {Link} from "react-router-dom";
import {routes} from "../../../routes/routes";
import manIcon from './../../../assets/vector-mobil.svg'

function LinkButton({title, type, handleClick}) {
    return (
        <div>
            {type === 'logout'
                ? <button onClick={handleClick} className={styles.link}>
                    {title}
                </button>
                :
                <Link className={styles.link} to={routes.authorization}>
                    <img className={styles.linkLogoIcon} src={manIcon} alt="icon"/>
                    {title}
                </Link>}

        </div>
    );
}

export default LinkButton;