const userAgent = navigator.userAgent.toLowerCase();
const isSafari = /version/.test(userAgent);

export const inputStylesGeneral = {
  container: (baseStyles) => ({
    ...baseStyles,
    width: '460px',
    minHeight: '40px',
    color: '#808099',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: "130%",
    '@media (max-width: 767px)': {
      width: '300px',
      minHeight: '38px',
    }
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderColor: state.isFocused ? '#BEBFD2' : '#BEBFD2',
    boxShadow: '',
    padding: '5px 10px',
    ':hover': {
      borderColor: '#BEBFD2',
      cursor: 'pointer',
    },
  }),
  valueContainer: (baseStyles) => ({
    ...baseStyles,
    padding: 0,
    color: '#282A43',
    display: 'flex',
    gap: 10,
  }),
  option: (baseStyles, state) => ({
    transition: 'all 0.4s',
    ':hover': {
      color: '#282A43',
    },
    color: state.isSelected && '#808099',
    padding: '7px 20px',
    display: 'flex',
    alignItems: 'center'
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    maxHeight: 400
  }),
  indicatorSeparator: (baseStyles) => ({
    ...baseStyles,
    display: 'none',
  }),
  dropdownIndicator: (baseStyles) => ({
    ...baseStyles,
    padding: '0',
  }),
  clearIndicator: () => ({
    padding: 10,
  }),
  multiValue: (base) => ({
    display: 'flex',
  }),
  multiValueLabel: (base) => ({
    ...base,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100px',
    height: '31px',
    padding: '5px 10px',
    paddingLeft: 10,
    fontWeight: 400,
    color: '#808099',
    backgroundColor: '#F2F6FF',
  }),
  multiValueRemove: (base) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#BEBFD2',
    borderRadius: '100%',
    width: 15,
    height: 15,
  }),
  placeholder: (base) => ({
    color: '#BEBFD2',
  }),
  input: (base) => ({
    ...base,
    fontSize: isSafari ? 16 : 14,
  }),
  singleValue: (base) => ({
    ...base,
    borderRadius: '100px',
    height: '31px',
    padding: '5px 10px',
    paddingLeft: 10,
    fontWeight: 400,
    color: '#808099',
    backgroundColor: '#F2F6FF',
  }),
}

export const stylesCurrency = {
  ...inputStylesGeneral,
  container: (baseStyles) => ({
    ...baseStyles,
    width: '88px',
    height: '28px',
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    minHeight: '28px',
    borderColor: state.isFocused ? '#BEBFD2' : '#BEBFD2',
    padding: '0px 10px',
    boxShadow: '',
    ':hover': {
      borderColor: '#BEBFD2',
      cursor: 'pointer',
    },
  }),
  valueContainer: (baseStyles) => ({
    ...baseStyles,
    padding: 0,
  }),
  singleValue: (baseStyles) => ({
    ...baseStyles,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '150%',
    color: '#808099',
  }),
  option: (baseStyles, state) => ({
    fontWeight: 600,
    fontSize: '14px',
    padding: '0 15px',
    transition: 'all 0.4s',
    color: state.isSelected ? '#282A43' : '#808099',
    ':hover': {
      color: '#282A43',
      cursor: 'pointer',
    },
  }),
}
