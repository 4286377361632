import React from 'react';
import styles from './VacanciesInternships.module.css'
import VacancyInternItem from "./VacancyInternItem/VacancyInternItem";
import {vacancies} from "../Home";
import LinkWithArrow from "../../../common/LinkWithArrow/LinkWithArrow";


const VacanciesInternships = ({title, linkTitle, path, type}) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.titleBlock}>
                    <span className={styles.title}>{title}</span>
                    <LinkWithArrow path={path} title={linkTitle}/>
                </div>
                <div className={styles.itemsContainer}>
                    {vacancies && vacancies.map((item) => {
                        return <VacancyInternItem type={type} key={item.id} id={item.id} date={item.date} company={item.company}
                                                  salary={item.salary} position={item.position}
                                                  workFormat={item.workFormat}
                                                  level={item.positionLevel} location={item.location}/>
                    })}
                </div>
            </div>
        </div>
    );
};

export default VacanciesInternships;