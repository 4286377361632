import React from 'react';
import styles from './LinkButtonMobile.module.css'
import {routes} from "../../../routes/routes";
import {Link} from "react-router-dom";
import profileIcon from './../../../assets/vector-mobil.svg'

const LinkButtonMobile = () => {
    return (
        <div className={styles.wrapper}>
            <Link to={routes.authorization}>
                <img src={profileIcon} alt={'profile-icon'}/>
            </Link>
        </div>
    );
};

export default LinkButtonMobile;