import React from "react";
import './Checkbox.css';

function Checkbox({label, value, setValue, removeValue, checked, id}) {
	return (
		<div className="flex items-center mb-3">
			<input
				id={id}
				type="checkbox"
				value={value}
				checked={checked}
				onChange={(e) => {
					if (e.target.checked) {
						setValue(e.target.value);
					} else {
						removeValue(e.target.value);
					}
				}}
				className="custom-checkbox"
			/>
			<label
				htmlFor={id}
				className="text-sm font-semibold text-gray dark:text-gray cursor-pointer"
			>
			{label}
			</label>
		</div>
	);
}

export default Checkbox;
