import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {checkAuth} from "./store/reducers/CheckAuthReducer"
import Main from "./common/Layout/Main/Main";
import { GoogleOAuthProvider } from '@react-oauth/google';

function App() {
    const dispatch = useDispatch();
    useEffect(() => {
        if (localStorage.getItem('access_token')) {
            dispatch(checkAuth())
        }

    }, [])

    return (
        <GoogleOAuthProvider clientId="657092219260-q2lthtu1ssv4tgmfu13734v1pkibfs7d.apps.googleusercontent.com">
            <Main/>
        </GoogleOAuthProvider>
    );
}

export default App;
