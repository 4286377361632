export const searchOption = (options, value) => {
  const activeOptions = [];

  for(let i = 0; i < options.length; i++) {
    if(options[i].label.toLowerCase().includes(value.toLowerCase())) {
      const searchIndex = options[i].label.toLowerCase().search(value.toLowerCase());
      const notActiveFirst = options[i].label.substr(0, searchIndex);
      const active = options[i].label.substr(searchIndex, value.length);
      const notActiveSecond = options[i].label.substr(searchIndex + value.length);
      const option = {...options[i], notActiveFirst: notActiveFirst, active: active, notActiveSecond: notActiveSecond}
      activeOptions.push(option);
    }
  }

  if(activeOptions.length !== 0) {
    return activeOptions.filter(Boolean);
  }
}