import axios from 'axios'

const currenciesNames = ['USD', 'RUB']

const getCurrency = async (currencyName) => {
  const response = await axios.get(`https://api.nbrb.by/ExRates/Rates/${currencyName}?ParamMode=2`)
  return {currencyName:currencyName, currencyScale: response.data.Cur_Scale, currencyOfficialRate: response.data.Cur_OfficialRate }
}

const setCurrenciesIntoSessionStorage = async () => {
  if (!sessionStorage.getItem('currenciesRates')) {
    const currenciesRates = await Promise.all(currenciesNames.map(cur => getCurrency(cur)))
    sessionStorage.setItem('currenciesRates', JSON.stringify(currenciesRates))
  }
}

const getCurrencyByName = (currencyName) => {
  const currencies = JSON.parse(sessionStorage.getItem('currenciesRates'))
  return currencies.filter(curr => curr.currencyName === currencyName)[0]
}

export { setCurrenciesIntoSessionStorage, getCurrencyByName }