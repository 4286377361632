import home from '../../assets/home.svg'
import internships from '../../assets/internships.png'
import job from '../../assets/job.svg'
import telegram from '../../assets/vector.svg'
import events from '../../assets/events.png'
import enterit from '../../assets/enterit.png'
import mentors from '../../assets/mentors.png'
import news from '../../assets/news.png'
import styles from './Sidebar.module.css'
import logoside from '../../assets/logoside.svg'
import more from './../../assets/more.png'
import business from '../../assets/business.svg'
import startups from '../../assets/startups.svg'
import documents from '../../assets/documents.svg'
import crossMobile from '../../assets/cross-white.svg'
import {Link} from 'react-router-dom'
import {routes} from '../../routes/routes'
import './Sidebar.css'
import React from 'react'
import LinkItem from "./LinkItem/LinkItem";
import ButtonMore from "./ButtonMore/ButtonMore";
import LinkButton from "../Header/LinkButton/LinkButton";
import {useDispatch, useSelector} from "react-redux";
import {logout, selectIsLoggedIn} from "../../store/reducers/LoginReducer";

export const navItems = [
    {title: 'Главная', icon: home, link: routes.home},
    {title: 'Вакансии', icon: job, link: routes.jobs},
    {title: 'Стажировки', icon: internships, link: routes.internships},
    {title: "Менторы", icon: mentors, link: routes.mentors},
    {title: "Новости", icon: news, link: routes.news},
    {title: "События", icon: events, link: routes.events},
    {title: 'Войти в IT', icon: enterit, link: routes.courses},

]
export const addItems = [
    {title: 'Бизнесу', icon: business, link: routes.forBusiness},
    {title: 'Стартапам', icon: startups, link: routes.forStartups},
    {title: 'Документы', icon: documents, link: routes.documents},
    {title: 'Телеграм', icon: telegram, link: routes.telegram},

]

function Sidebar({setOpenMenu, openMenu, type}) {
    const dispatch = useDispatch()
    const [showMore, setShowMore] = React.useState(false)
    const isLoggedIn = useSelector(selectIsLoggedIn)
    const wrapperClass = (type === 'desktop' && styles.wrapper) || (type === 'mobile' && styles.wrapper && (openMenu ? styles.wrapperMobile : styles.wrapperNone))
    const year = new Date().getFullYear()
    const handleShowMore = () => {
        setShowMore(true)
    }
    const handleLogout = () => {dispatch(logout())}
    return (
        <div className={wrapperClass}>
            <div className={styles.logoLink}>
                <Link className={styles.logo} to={routes.home}>
                    <img src={logoside} alt="itcoty"/>
                </Link>
            </div>
            <div className={styles.headerBlock}>
                <img onClick={() => setOpenMenu(false)} className={styles.headerCloseIcon} src={crossMobile}
                     alt="crossMobile"/>

                {isLoggedIn ? <LinkButton handleClick={handleLogout} title={'Выход'} type={'logout'} />: <LinkButton title={'Вход'}/>}
            </div>
            {navItems.map((item, index) => {
                return <LinkItem key={index} icon={item.icon} link={item.link} title={item.title}/>
            })}
            {type === 'desktop' && showMore && addItems.map((item, index) => {
                return <LinkItem key={index} icon={item.icon} link={item.link} title={item.title}/>
            })}
            {type === 'mobile' && openMenu && addItems.map((item, index) => {
                return <LinkItem key={index} icon={item.icon} link={item.link} title={item.title}/>
            })}
            {!showMore && <ButtonMore handleShowMore={handleShowMore} icon={more}/>}
            <div className={styles.footerMobileBlock}>
                <span>+ 375 (29) 333 66 55</span>
                <span>it-coty@gmail.com</span>
                <span>© {year}. ООО «IT Coty»</span>
            </div>
        </div>
    )
    // return width > 600 ? (
    //     <div className='sidebar-container bg-midnight'>
    //         <div className='logo-container'>
    //             <Link to="/">
    //                 <img
    //                     alt='logo'
    //                     src={logoside}
    //                     className={'logo'}
    //                 />
    //             </Link>
    //         </div>
    //         <div className='navbar-container'>
    //             <ul className='items-navbar'>
    //                 {navItems.map((navItem, index) => (
    //                     <li
    //                         className=' item'
    //                         key={index}>
    //                         <NavLink
    //                             to={navItem.link}
    //                             className='flex flex-col items-center r p-2'>
    //                             <div>
    //                                 <img src={navItem.icon} alt={'logo'}/>
    //                             </div>
    //                             <div className=''>{navItem.title}</div>
    //                         </NavLink>
    //                     </li>
    //                 ))}
    //             </ul>
    //         </div>
    //         <div className='justify-self-end mb-20 hover:opacity-75'>
    //             {/* <a href="#" className="flex flex-col items-center mt-10">
    // 					<img src={more} />
    // 					<span>Больше</span>
    // 				</a> */}
    //         </div>
    //         {/* </div> */}
    //     </div>
    // ) : (
    //     <div className={'sidebar-mobile'}>
    //         <nav>
    //             <div
    //                 className='burger-btn'
    //                 onClick={() => setMenuActive(!menuActive)}></div>
    //         </nav>
    //         <div className='navbar-container'>
    //             {menuActive ? <div className='blur' onClick={() => setMenuActive(false)}/> : null}
    //             <div
    //                 className={menuActive ? 'menu active' : 'menu'}
    //                 onClick={() => setMenuActive(false)}>
    //                 <div
    //                     className='menu__content'
    //                     onClick={e => e.stopPropagation()}>
    //                     <ul className='items-navbar'>
    //                         {navItems.map((navItem, index) => (
    //                             <li
    //                                 className='item'
    //                                 key={index}>
    //                                 <NavLink
    //                                     to={navItem.link}
    //                                     className='flex flex-col items-center r p-2 '>
    //                                     <div>
    //                                         <img src={navItem.icon} alt={'logo'}/>
    //                                     </div>
    //                                     <div className=''>{navItem.title}</div>
    //                                 </NavLink>
    //                             </li>
    //                         ))}
    //                     </ul>
    //                     <div className='contacts'>
    //                         <span>+ 375 (29) 333 66 55 </span>
    //                         <span>it-coty@gmail.com</span>
    //                         <span>© 2023. ООО «IT Coty»</span>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // )
}

export default Sidebar
