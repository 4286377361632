import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, EffectCoverflow, Autoplay } from 'swiper/core'
import logo1 from '../../assets/slide1mobile.png'
import logo2 from '../../assets/slide2mobile.png'
import logo3 from '../../assets/slide3mobile.png'
import logo4 from '../../assets/slide4mobile.png'
import logo5 from '../../assets/slide5mobile.png'
import logo6 from '../../assets/slide6mobile.png'

import logoDesktop1 from '../../assets/slide1.png'
import logoDesktop2 from '../../assets/slide2.png'
import logoDesktop3 from '../../assets/slide3.png'
import logoDesktop4 from '../../assets/slide4.png'
import logoDesktop5 from '../../assets/slide5.png'
import logoDesktop6 from '../../assets/slide6.png'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import './style.css'
import 'swiper/css/effect-coverflow'

import { Navigation } from 'swiper'
import React, { useEffect } from 'react'
import { useWindowSize } from '../../helpers/useWindowSize'
import { useMediaQuery } from 'react-responsive'

const slidesText = [
	{
		id: 1,
		title: 'Удобный поиск вакансий и стажировок в IT',
		description: 'Найди свой вариант прямо сейчас',
		titleSlide: 'Работа в IT',
		mobileLogo: logo1,
		desktopLogo: logoDesktop1
	},
	{
		id: 2,
		title: 'Менторы',
		description: 'Наставники, которые ускорят твое развитие в IT',
		titleSlide: 'Менторы',
		mobileLogo: logo2,
		desktopLogo: logoDesktop2
	},
	{
		id: 3,
		title: 'Telegram каналы с IT профессиями',
		description: 'Не пропусти самые свежие вакансии',
		titleSlide: 'Telegram',
		mobileLogo: logo6,
		desktopLogo: logoDesktop6
	},
	{
	id: 4,
	title: 'IT курсы',
	description: 'Для быстрого изучения востребованной специальности',
	titleSlide: 'Курсы',
	mobileLogo: logo3,
	desktopLogo: logoDesktop3
},
]

SwiperCore.use([Pagination, Autoplay])
export default function Carousel({ handleButtonClicked }) {
	const screenWidth = window.screen.width
	const screenHeight = window.screen.height

	const isLandscape = useMediaQuery({ query: '(orientation: landscape)' })
	const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

	// useEffect(() => {}, [isLandscape, isPortrait])

	const slides = slidesText.map((slide, index) => (
		<SwiperSlide
			key={index}
			id={`slide${index + 1}`}
			className='swiper-fixed-width-660'
			onClick={() => handleButtonClicked(slide.titleSlide)}>
			{screenWidth > 600 ? (
				<div>
					<h2>{slide.title}</h2>
					<p>{slide.description}</p>
					<button
						onClick={() => handleButtonClicked(slide.titleSlide)}
						className=' buttonSwiper
										mt-5
										px-11
										py-2
										bg-buttonblue
										text-white
										font-medium
										text-[14px]
										rounded
										block 
										hover:shadow-lg
										focus:shadow-lg 
										active:shadow-lg
										transition
										duration-150
										ease-in-out'>
						Подробнее
					</button>
				</div>
			) : (
				<div className='modileSwiperContainer'>
					<div className='descriptionMobileSlide'>
						<h2>{slide.title}</h2>
						<p>{slide.description}</p>
					</div>
					<div
						id={`slide${index + 1}`}
						className='logoMobileSlideContainer'>
						<img
							src={slide.mobileLogo}
							className='logoMobileSlide'
						/>
					</div>
				</div>
			)}
		</SwiperSlide>
	))
	if (screenWidth > 1000) {
		return (
			<div className='sw-container'>
				<Swiper
					slidesPerView={1.2}
					centeredSlides={true}
					loop={true}
					autoplay={true}
					pagination={{
						clickable: true,
						renderBullet: function (index, className) {
							let newClassName = className

							if (index > 5) {
								newClassName = 'empty'
							}

							return (
								'<div class="' +
								newClassName +
								'">' +
								`<img src='https://i.ibb.co/821D6sV/bullet.png'/>` +
								'<div  className="bullet-text" >' +
								slidesText[index]?.titleSlide +
								'</div>' +
								'</div>'
							)
						}
					}}
					coverflowEffect={{
						rotate: -2,
						stretch: '-80%',
						depth: 300,
						modifier: -1,
						slideShadows: false
					}}
					modules={[Pagination, Navigation, EffectCoverflow]}
					effect={'coverflow'}
					className='mySwiper h-full w-full'>
					{slides}
				</Swiper>
			</div>
		)
	}
	return (
		<div className='sw-container'>
			<Swiper
				pagination={true}
				modules={[Pagination]}
				spaceBetween={200}
				className='mySwiper h-full w-full'>
				{slides}
			</Swiper>
		</div>
	)
}
