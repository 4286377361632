import React from 'react';
import styles from './FooterBanner.module.css'
import arrow from './../../../assets/selectCourse.png'

const FooterBanner = () => {
    return (
        <div className={styles.wrapper}>
            <span className={styles.mvpConnect}>MVP Connect</span>
            <a className={styles.linkBlock} href={'https://mvp.by'} target={'_blank'}>
                <span>Выбрать курс</span>
                <img src={arrow} alt="arrow"/>
            </a>
            <div className={styles.container}>
                <span className={styles.title}>IT- курсы и практика на коммерческих проектах и стартапах</span>

            </div>
        </div>
    );
};

export default FooterBanner;