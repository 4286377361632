import React from 'react';
import styles from './NewsContainer.module.css'
import NewsInitialItem from "./NewsInitialItem/NewsInitialItem";
import NewsItem from "./NewsItem/NewsItem";

const NewsContainer = ({news}) => {
    return (
        <div className={styles.wrapper}>
            <NewsInitialItem title={'Последние новости'} type={'start'}/>
            {news && news.map((item) => {
                return <NewsItem key={item.id}
                                 id={item.id} image={item.image}
                                 date={item.date}
                                 commentsCounts={item.commentCounts}
                                 likeCounts={item.likeCounts}
                                 newsTitle={item.title}
                                 newsText={item.text}/>
            })}
            <NewsInitialItem title={'Популярные новости'}/>
        </div>
    );
};

export default NewsContainer;