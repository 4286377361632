import React from 'react';
import { components } from "react-select";
import cross from "../../assets/cross.svg";

export const ClearIndicator = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <img src={cross} alt="cross" />
    </components.ClearIndicator>
  );
};