import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import VacanciesAPI from "../../http/VacanciesAPI";
import { sortByDate } from "../../helpers/dateFormat";

export const initialState = {
  allVacancies: [],
  amount: 0,
  isPending: false,
  vacanciesError: '',
  chosenJob: {},
  filters: null,
  lastVacancyID: '',
	direction: '',
	specialization: [''],
	job_type: [
		{label: "Гибкий график", value: "flexible", checked: false},
		{label: "Удаленая работа", value: "remote", checked: false},
		{label: "Полный день", value: "fulltime", checked: false},
		{label: "В офисе", value: "office", checked: false},
		{label: "Гибридная", value: "office/remote", checked: false},
	],
	selectedLanguages: [],
	currency: {value: "USD", label: "USD"},
	level: [
		// {label: "Все варианты", value: "all", checked: true},
		{label: "Trainee", value: "trainee", checked: false},
		{label: "Junior", value: "junior", checked: false},
		{label: "Middle", value: "middle", checked: false},
		{label: "Senior", value: "senior", checked: false},
		{label: "Director", value: "director", checked: false},
		{label: "Lead", value: "lead", checked: false},
	],
	typeOfEmployment: [
		// {label: "Все варианты", value: "all", checked: true},
		{label: "Полная занятость", value: "fulltime", checked: false},
		{label: "Частичная занятость", value: "parttime", checked: false},
		{label: "Волонтерство", value: "volunteering", checked: false},
		{label: "Контракт", value: "contract", checked: false},
		{label: "Фриланс", value: "freelance", checked: false},
		{label: "Стажировка", value: "internship", checked: false},
	],
	companyType: [
		// {label: "Все варианты", value: "all", checked: true},
		{label: "Продуктовая", value: "product", checked: false},
		{label: "Аутсортсинговая", value: "outsourcing", checked: false},
		{label: "Консалтинговая", value: "consulting", checked: false},
		{label: "Аутстаф", value: "outstaff", checked: false},
		{label: "Стартап", value: "startup", checked: false},
		{label: "IT - отделы нетехнических компаний", value: "notTechnical", checked: false},
	],
	countries: [],
	cities: [],
	states: [],
	companyScope: [],
	companySize: [
		{label: "1 - 200 сотрудников", value: "1-200", checked: false},
		{label: "201 - 500 сотрудников", value: "201-500", checked: false},
		{label: "501 - 1000 сотрудников", value: "501-1000", checked: false},
		{label: " 1000 + сотрудников", value: "1000", checked: false},
	],
	salaryOptionSelect: 'perMonth',
	salaryFrom: '',
	salaryTo: '',
	isVacancyHome: false,
	isClearFilters: false,
};

export const getVacanciesScroll = createAsyncThunk(
	'vacancies/getVacanciesScroll',
	async (data, {rejectWithValue}) => {
	  try {
		const result = await VacanciesAPI.getVacancies(data);
		return result;
	  } catch (err) {
		return rejectWithValue(err.message);
	  }
	}
  );

export const getVacancies = createAsyncThunk(
	'vacancies/getVacancies',
	async (data, {rejectWithValue}) => {
	  try {
		const result = await VacanciesAPI.getVacancies(data);
		return result;
	  } catch (err) {
		return rejectWithValue(err.message);
	  }
	}
  );

export const getVacancyById = createAsyncThunk(
  'vacancies/getVacancyById',
  async (id, {rejectWithValue}) => {
    try {
      const data = await VacanciesAPI.getVacancyById(id);
      return data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const filterVacancies = createAsyncThunk(
  'vacancies/filterVacancies',
  async (filters, {rejectWithValue}) => {
    try {
      const data = await VacanciesAPI.filterVacancies(filters);
      return data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

const vacanciesSlice = createSlice({
	name: 'vacancies',
	initialState,
	reducers: {
		setDirection: (state, action) => {
      state.direction = action.payload;
    },
		setSpecialization: (state, action) => {
      state.specialization = Object.assign([], action.payload);;
    },
		setJobType: (state, action) => {
			state.job_type.forEach((option) => {
				if (option.value === action.payload) {
				option.checked = true;
				}
			});
    },
		removeJobType: (state, action) => {
			state.job_type.forEach((option) => {
				if (option.value === action.payload) {
				option.checked = false;
				}
			});
    },
		clearJobType: (state, action) => {
			state.job_type = action.payload;
    },
		setLanguages: (state, action) => {
      state.selectedLanguages = Object.assign([], action.payload);
    },
		setCurrency: (state, action) => {
      state.currency = action.payload;
    },
		setLevel: (state, action) => {
			state.level.forEach((option) => {
				if (option.value === action.payload) {
				option.checked = true;
				}
			});
    },
		removeLevel: (state, action) => {
			state.level.forEach((option) => {
				if (option.value === action.payload) {
				option.checked = false;
				}
			});
    },
		clearLevel: (state, action) => {
			state.level = action.payload;
    },
		setTypeOfEmployment: (state, action) => {
			state.typeOfEmployment.forEach((option) => {
				if (option.value === action.payload) {
				option.checked = true;
				}
			});
    },
		removeTypeOfEmployment: (state, action) => {
			state.typeOfEmployment.forEach((option) => {
				if (option.value === action.payload) {
				option.checked = false;
				}
			});
    },
		clearTypeOfEmployment: (state, action) => {
			state.typeOfEmployment = action.payload;
    },
		setCompanyType: (state, action) => {
			state.companyType.forEach((option) => {
				if (option.value === action.payload) {
				option.checked = true;
				}
			});
    },
		removeCompanyType: (state, action) => {
			state.companyType.forEach((option) => {
				if (option.value === action.payload) {
				option.checked = false;
				}
			});
    },
		clearCompanyType: (state, action) => {
			state.companyType = action.payload;
    },
		setCountries: (state, action) => {
      state.countries = Object.assign([], action.payload);
    },
		setCountriesByCity: (state, action) => {
			if(!state.countries.length) {
				state.countries = state.countries.concat(action.payload);
			}
    },
		setCities: (state, action) => {
      state.cities = Object.assign([], action.payload);
    },
		setStates: (state, action) => {
      state.states = Object.assign([], action.payload);
    },
		setCompanyScope: (state, action) => {
      state.companyScope = Object.assign([], action.payload);
    },
		setCompanySize: (state, action) => {
			state.companySize.forEach((option) => {
				if (option.value === action.payload) {
				option.checked = true;
				}
			});
    },
		removeCompanySize: (state, action) => {
			state.companySize.forEach((option) => {
				if (option.value === action.payload) {
				option.checked = false;
				}
			});
    },
		clearCompanySize: (state, action) => {
			state.companySize = action.payload;
    },
		setSalaryOption: (state, action) => {
			state.salaryOptionSelect = action.payload;
    },
		clearSalaryOption: (state, action) => {
			state.salaryOption = action.payload;
    },
		setSalaryFrom: (state, action) => {
      state.salaryFrom = /\d+/.test(Number(action.payload)) ? action.payload : state.salaryFrom;
    },
		setSalaryTo: (state, action) => {
      state.salaryTo = /\d+/.test(Number(action.payload)) ? action.payload : state.salaryTo;;
    },
		setVacancies: (state, action) => {
      state.allVacancies = [action.payload];
    },
		isVacancyHomeReducer: (state, action) => {
      state.isVacancyHome = action.payload;
    },
		isClearFiltersReducer: (state, action) => {
      state.isClearFilters = action.payload;
    },
		removeAllVacancies: (state) => {
			state.allVacancies = [];
    },
	setChosenJob: (state, action) => {
		state.chosenJob = action.payload;
	}, 
	setFilters: (state, action) => {
		state.filters = action.payload;
	},
	clearAllVacancies: (state, action) => {
		state.allVacancies = [];
	},
		setLastVacancyID: (state, action) => {
			state.lastVacancyID = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getVacancies.pending, (state) => {
			state.isPending = true;
			state.allVacancies = [];
		});
		builder.addCase(getVacancies.fulfilled, (state, action) => {
			state.isPending = false;
			
			if(action.payload.amount)
				state.amount = action.payload.amount
			else if(action.payload.amount === 0)
				state.amount = 0
			else
				state.amount = 1000000

			state.allVacancies.push(...Object.assign([], action.payload.vacancies))
		});
    	builder.addCase(getVacancies.rejected, (state, action) => {
			state.isPending = false;
			state.vacanciesError = action.payload;
		});

		builder.addCase(getVacanciesScroll.pending, (state) => {
			state.isPending = true;
		});
		builder.addCase(getVacanciesScroll.fulfilled, (state, action) => {
			state.isPending = false;
			
			if(action.payload.amount)
				state.amount = action.payload.amount
			else if(action.payload.amount === 0)
				state.amount = 0
			else
				state.amount = 1000000

			state.allVacancies.push(...Object.assign([], action.payload.vacancies))
		});
    	builder.addCase(getVacanciesScroll.rejected, (state, action) => {
			state.isPending = false;
			state.vacanciesError = action.payload;
		});

		builder.addCase(filterVacancies.pending, (state) => {
			state.isPending = true;
		});
		builder.addCase(filterVacancies.fulfilled, (state, action) => {
			state.isPending = false;
			state.allVacancies = sortByDate(Object.assign([], action.payload.vacancies));
		});
    builder.addCase(filterVacancies.rejected, (state, action) => {
			state.isPending = false;
			state.vacanciesError = action.payload;
		});

		builder.addCase(getVacancyById.pending, (state) => {
			state.isPending = true;
		});
		builder.addCase(getVacancyById.fulfilled, (state, action) => {
			state.isPending = false;
			state.chosenJob = action.payload;
		});
    builder.addCase(getVacancyById.rejected, (state, action) => {
			state.isPending = false;
			state.vacanciesError = action.payload;
		});
	},
});

export const vacanciesReducer = vacanciesSlice.reducer;
export const {
  setDirection,
	setSpecialization,
	setJobType,
	removeJobType,
	setLanguages,
	setCurrency,
	setLevel,
	removeLevel,
	clearLevel,
	setTypeOfEmployment,
	removeTypeOfEmployment,
	setCompanyType,
	removeCompanyType,
	setCountries,
	setCities,
	setStates,
	setCompanyScope,
	setCompanySize,
	removeCompanySize,
	setSalaryOption,
	setSalaryFrom,
	setSalaryTo,
	clearCompanySize,
	clearCompanyType,
	clearJobType,
	clearSalaryOption,
	clearTypeOfEmployment,
	setCountriesByCity,
	setVacancies,
	isVacancyHomeReducer,
	isClearFiltersReducer,
	removeAllVacancies,
	setChosenJob, 
	setFilters,
	clearAllVacancies,
	setLastVacancyID
} = vacanciesSlice.actions;