import React, {useEffect} from 'react';
import styles from './ActivationEmailPage.module.css'
import {Navigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import {activation, selectEmailConfirmed} from "../../../store/reducers/RegistrationReducer";
import {routes} from "../../../routes/routes";

const ActivationEmailPage = () => {
    // const location = useLocation();
    const params = useParams()
    const dispatch = useDispatch();
    // const errorMessage = useSelector(selectErrorRegistration)
    const emailConfirmed = useSelector(selectEmailConfirmed)
    useEffect(() => {
        // const data = {linkEmail: location.pathname, key: params.key};
        dispatch(activation(params.key))
    }, []);
    if (emailConfirmed) {
        setTimeout(() => {
            return <Navigate to={routes.authorization}/>
        }, 3000)
    }
    return (
        <div className={styles.wrapper}>
            <h3>Активация аккаунта...</h3>
            {!emailConfirmed ? <h4>Что-то пошло не так. Попробуйте позже</h4> : <h4>Ваша учетная запись активирована</h4>}
        </div>
    );
};

export default ActivationEmailPage;