import React from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Link} from "react-router-dom";
import {routes} from "../../../../routes/routes";
import {regValidSchema} from "./schema/validationSchema/validation";
import {regFormSchema} from "./schema/formSchema/formSchema";
import {SocialLoginOptions} from "../../SocialLoginOptions/SocialLoginOptions";
import styles from './../../Authorization/AuthorizationForm/AuthorizationForm.module.css'
import AuthInput from "../../../../common/AuthInput/AuthInput";
import {SuccessfulRegistration} from "./SuccessfulRegistration";
import {useDispatch, useSelector} from "react-redux";
import {
    registration,
    selectErrorRegistration,
    selectIsRegistered
} from "../../../../store/reducers/RegistrationReducer";

export const RegistrationForm = () => {
    const dispatch = useDispatch()
    const isRegistered = useSelector(selectIsRegistered)
    const errorMessage = useSelector(selectErrorRegistration)
    console.log('errorMessage:', errorMessage)
    const {
        register,
        handleSubmit,
        formState: {errors, isValid, isDirty},
        getValues,
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(regValidSchema),
    });

    const onSubmit = (data) => {
        const reqData = {email: data.email, password: data.password, confirmPassword: data.confirmPassword};
        dispatch(registration(reqData))
    };
    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                {!isRegistered && <h3 className={styles.title}>Создать аккаунт</h3>}
                {isRegistered ? <SuccessfulRegistration email={getValues('email')}/> :
                    <form action="" className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
                        {regFormSchema.map((field) => {
                            return <AuthInput name={field.name} key={field.name} label={field.label} type={field.type}
                                              placeholder={field.placeholder} register={register} errors={errors}
                                              isDirty={isDirty}/>
                        })}
                        <div className={styles.privacyPolicyBlock}>
                            <div className={styles.privacyBlock}>
                                <input type="checkbox"
                                       className={`${styles.checkbox} ${errors.conditions ? styles.errorCheckbox : ''}`}
                                       id={'conditions'} {...register('conditions')}/>
                                <span className={styles.privacy}>Подтверждаю, что ознакомлен, полностью согласен и принимаю условия <Link
                                    className={styles.privacyLink}
                                    to={routes.privacyPolicy}> Пользовательского соглашения</Link></span>
                            </div>

                            {errors?.conditions &&
                                <span className={styles.errorCheckboxSpan}>{errors.conditions.message}</span>}
                        </div>
                        {errorMessage && <span className={styles.errorMessage}>{errorMessage}</span>}
                        <div className={styles.buttonsBlock}>
                            <button className={styles.button} type={"submit"} disabled={!isValid}>Зарегистрироваться
                            </button>
                            <span>Уже есть аккаунт?</span>
                            <Link to={routes.authorization} className={styles.registrationLink}>Войти</Link>
                        </div>
                        <div className={styles.bottomBlock}>
                            <SocialLoginOptions/>
                        </div>
                    </form>
                }
            </div>
        </div>
    );
};
