import React from 'react';
import styles from './Main.module.css'
import Sidebar from "../../Sidebar/Sidebar";
import Header from "../../Header/Header";
import {Route, Routes} from "react-router-dom";
import {routes} from "../../../routes/routes";
import Home from "../../../pages/home/Home";
import Jobs from "../../../pages/Jobs/Jobs";
import Registration from "../../../pages/User/Registration/Registration";
import Authorization from "../../../pages/User/Authorization/Authorization";
import Telegram from "../../../pages/Telegram/Telegram";
import ActivationEmailPage from "../../../pages/User/ActivationEmailPage/ActivationEmailPage";


const Main = () => {
    return (
        <div className={styles.wrapper}>
            <Sidebar type={'desktop'}/>
            <div className={styles.mainWrapper}>
                <Header/>
                <div className={styles.routesContainer}>
                    <Routes>
                        <Route path={routes.home} element={<Home/>}/>
                        <Route path={routes.jobs} element={<Jobs/>}>
                            <Route path={routes.jobsVacancies} element={<div>Vacancies</div>}/>
                            <Route path={routes.jobsInternships} element={<div>Internships</div>}/>
                        </Route>
                        <Route path={routes.registration} element={<Registration/>}/>
                        <Route path={routes.authorization} element={<Authorization/>}/>
                        <Route path={routes.telegram} element={<Telegram/>}/>
                        <Route path={routes.activateEmail} element={<ActivationEmailPage/>}/>
                    </Routes>
                </div>

            </div>
        </div>
    );
};

export default Main;