import React, {useState} from 'react';
import styles from './FilterSelect.module.css'
import arrow from './../../assets/arrow.svg'

const FilterSelect = ({label, defaultValue, options}) => {
    const [selectedOption, setSelectedOptions] = useState(defaultValue)
    const [showOptions, setShowOptions] = useState(false)
    const handleSelect = (name, value) => {
        setSelectedOptions(name)
        setShowOptions(false)
    }
    return (
        <div className={styles.wrapper}>
            <div className={styles.labelContainer} onClick={() => setShowOptions(!showOptions)}>
                <span className={styles.label}>{label}</span>
                <div className={styles.selectBox}>
                    {selectedOption}
                    <img className={styles.arrow} src={arrow} alt="Arrow"/>
                </div>
                <span className={styles.line}></span>
            </div>
            <div className={`${styles.options} ${showOptions ? styles.optionsShow : ''}`}>
                {options.map((option, index) => (
                    <div key={index} className={styles.option}
                         onClick={() => handleSelect(option.label, option.value)}>
                        {option.label}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FilterSelect;