import React from "react";
import styles from './SearchInput.module.css'
import searchIcon from './../../assets/search.svg'

function SearchInput() {

    return (
        <div className={styles.searchInputWrapper}>
            <form action="">
                <input type="text" placeholder={'Поиск'} className={styles.searchInput} name={'search'}/>
            </form>
            <img className={styles.searchLogo} src={searchIcon} alt="Search"/>

        </div>
    );
}

export default SearchInput;
