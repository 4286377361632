export const API_URL = `https://4dev.itcoty.ru/api/v1/`
//export const API_URL = `https://4dev.itcoty.ru/`
const ENDPOINTS = {
  getAllVacancies: 'get-all-vacancies',
  getNewVacancies: 'three-last-vacancies?common_vacancies',
  getVacancies: 'vacancies',
  getVacancyById: 'vacancy?id=',
}

const METHODS = {
  get: 'GET',
  post: 'POST',
  delete: 'DELETE',
  put: 'PUT',
}

export default class VacanciesAPI {
  static async getVacancies(data) {
    const {lastVacancyID, filters} = data
    let requestString = ''
    let response = null

    lastVacancyID
      ? (requestString = `${API_URL}${ENDPOINTS.getVacancies}?id=${lastVacancyID}&limit=20`)
      : (requestString = `${API_URL}${ENDPOINTS.getVacancies}?limit=20`)
    try {
      filters
        ? (response = await fetch(`${API_URL}${ENDPOINTS.getVacancies}`, {
            method: METHODS.post,
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(filters),
          }))
        : (response = await fetch(requestString, {
            method: METHODS.get,
            headers: {
              'Access-Control-Allow-Origin': '*',
            },
          }))
      if (response.ok) {
        const data = await response.json()
        return data
      } else {
        return await Promise.reject(new Error(response.statusText))
      }
    } catch (error) {
      throw new Error('Вакансии не найдены!')
    }
  }

  // static async filterVacancies(filters) {
  //   try {
  //     const response = await fetch(`${API_URL}${ENDPOINTS.filters}`, {
  //       method: METHODS.post,
  //       headers: {
  //         Accept: 'application/json',
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(filters),
  //     })
  //     if (response.ok) {
  //       const data = await response.json()
  //       return data
  //     } else {
  //       return await Promise.reject(new Error(response.statusText))
  //     }
  //   } catch (error) {
  //     throw new Error('Вакансии не найдены!')
  //   }
  // }

  static async getNewVacancies() {
    try {
      const response = await fetch(`${API_URL}${ENDPOINTS.getNewVacancies}`, {
        method: METHODS.get,
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      })
      if (response.ok) {
        const data = await response.json()
        return data
      } else {
        return await Promise.reject(new Error(response.statusText))
      }
    } catch (error) {
      throw new Error('Вакансии не найдены!')
    }
  }

  static async getVacancyById(id) {
    try {
      const response = await fetch(`${API_URL}${ENDPOINTS.getVacancyById}${id}`, {
        method: METHODS.get,
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      })
      if (response.ok) {
        const data = await response.json()
        return data
      } else {
        return await Promise.reject(new Error(response.statusText))
      }
    } catch (error) {
      throw new Error('Вакансии не найдены!')
    }
  }
}
