import React from 'react'
import styles from './Home.module.css'
import {useNavigate} from 'react-router-dom'
import Carousel from '../../common/Swiper/Carousel'
import './Home.css'
import {routes} from "../../routes/routes";
import VacanciesInternships from "./VacanciesInternships/VacanciesInternships";
import NewsContainer from "./NewsContainer/NewsContainer";
import newsImage1 from './../../assets/news1.png'
import newsImage2 from './../../assets/news2.png'
import newsImage3 from './../../assets/news3.png'
import newsImage4 from './../../assets/news4.png'
import newsImage5 from './../../assets/news5.png'
import newsImage6 from './../../assets/news6.png'
import FooterBanner from "./FooterBanner/FooterBanner";

export const news = [
    {
        id: 1,
        title: 'Проснуться сеньором: как понять, что вы переросли свой грейд',
        text: 'Грейд разработчика может меняться от компании к компании и даже от проекта к проекту ....',
        date: '31 октября 2024',
        likeCounts: 10,
        commentCounts: 10,
        image: newsImage1,
    },
    {
        id: 2,
        title: 'Проснуться сеньором: как понять, что вы переросли свой грейд',
        text: 'Грейд разработчика может меняться от компании к компании и даже от проекта к проекту ....',
        date: '31 октября 2024',
        likeCounts: 10,
        commentCounts: 10,
        image: newsImage2,
    },
    {
        id: 3,
        title: 'Проснуться сеньором: как понять, что вы переросли свой грейд',
        text: 'Грейд разработчика может меняться от компании к компании и даже от проекта к проекту ....',
        date: '31 октября 2024',
        likeCounts: 10,
        commentCounts: 10,
        image: newsImage3,
    },
    {
        id: 4,
        title: 'Проснуться сеньором: как понять, что вы переросли свой грейд',
        text: 'Грейд разработчика может меняться от компании к компании и даже от проекта к проекту ....',
        date: '31 октября 2024',
        likeCounts: 10,
        commentCounts: 10,
        image: newsImage4,
    },
    {
        id: 5,
        title: 'Проснуться сеньором: как понять, что вы переросли свой грейд',
        text: 'Грейд разработчика может меняться от компании к компании и даже от проекта к проекту ....',
        date: '31 октября 2024',
        likeCounts: 10,
        commentCounts: 10,
        image: newsImage5,
    },
    {
        id: 6,
        title: 'Проснуться сеньором: как понять, что вы переросли свой грейд',
        text: 'Грейд разработчика может меняться от компании к компании и даже от проекта к проекту ....',
        date: '31 октября 2024',
        likeCounts: 10,
        commentCounts: 10,
        image: newsImage6,
    },
]
export const vacancies = [
    {
        id: 1,
        company: 'Wargaming',
        date: 'Сегодня, 10:00',
        position: "Backend Developer",
        positionLevel: 'Middle',
        salary: '$800-$12K',
        location: "Minsk, BY",
        workFormat: 'Part time, Office'
    },
    {
        id: 2,
        company: 'Wargaming',
        date: 'Сегодня, 10:00',
        position: "Backend Developer",
        positionLevel: 'Middle',
        salary: '$800-$12K',
        location: "Minsk, BY",
        workFormat: 'Part time, Office'
    },
    {
        id: 3,
        company: 'Wargaming',
        date: 'Сегодня, 10:00',
        position: "Backend Developer",
        positionLevel: 'Middle',
        salary: '$800-$12K',
        location: "Minsk, BY",
        workFormat: 'Part time, Office'
    },

]
export default function Home() {

    let navigate = useNavigate()

    const handleButtonClicked = (titleSlide) => {
        switch (titleSlide) {
            case 'Telegram':
                navigate(routes.telegram);
                break;
            case 'Работа в IT':
                navigate(routes.jobs);
                break;
            default:
                navigate(routes.placeholder);
                break;
        }
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <Carousel handleButtonClicked={handleButtonClicked}/>
                <div className={styles.vacanciesContainer}>
                    <VacanciesInternships type={'vacancies'} title={'Новые вакансии'} linkTitle={'Все вакансии'}
                                          path={routes.jobs}/>
                    <VacanciesInternships title={'Новые стажировки'} linkTitle={'Все стажировки'}
                                          path={routes.internships}/>
                </div>
                {news && <NewsContainer news={news}/>}
                <div className={styles.bannerBlock}>
                    <FooterBanner/>
                </div>
            </div>
        </div>
    )
}
