import React from 'react';
import { components } from "react-select";
import dropdown from "../../assets/select.svg";

export const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={dropdown} alt="dropdown" />
    </components.DropdownIndicator>
  );
};