export const regFormSchema = [
  {
    label: "Электронная почта",
    placeholder: "Введите ваш email",
    name: "email",
    type: "email",
  },
  {
    label: "Пароль",
    placeholder: "Введите пароль",
    name: "password",
    type: "password",
  },
  {
    label: "Повторите пароль",
    placeholder: "Повторите пароль",
    name: "confirmPassword",
    type: "password",
  }
];
