import React from "react";
import styles from './RegAuthLayout.module.css'

export const RegAuthLayout = ({children}) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.imageBlock}></div>
            <div className={styles.authBlock}>
                {children}
            </div>
        </div>
    );
};
