import { Link } from "react-router-dom";
import { routes } from "../../../../../../routes/routes";
import styles from './../../AuthorizationForm.module.css'
export const authFormSchema = [
  {
    label: "Электронная почта",
    placeholder: "Введите ваш email",
    name: "email",
    type: "email",
  },
  {
    label: (
      <div className={styles.labelBlock}>
        <span>Пароль</span>
        <Link to={routes.forgotPassword} className={styles.forgotPasswordLink}>
          Забыли пароль?
        </Link>
      </div>
    ),
    placeholder: "Введите пароль",
    name: "password",
    type: "password",
  },
];
