import React from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Link} from "react-router-dom";
import {routes} from "../../../../routes/routes";
import {authValidSchema} from "./schema/validationSchema/validation";
import {authFormSchema} from "./schema/formSchema/formSchema";
import {SocialLoginOptions} from "../../SocialLoginOptions/SocialLoginOptions";
import styles from './AuthorizationForm.module.css'
import AuthInput from "../../../../common/AuthInput/AuthInput";
import {useDispatch, useSelector} from "react-redux";
import {login, selectLoginError} from "../../../../store/reducers/LoginReducer";

export const AuthorizationForm = () => {
    const dispatch = useDispatch()
    const errorMessage = useSelector(selectLoginError)
    const {
        register,
        handleSubmit,
        formState: {errors, isValid, isDirty},
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(authValidSchema),
    });

    const onSubmit = (data) => {
        const reqData = {email: data.email, password: data.password};
        dispatch(login(reqData))
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <h2 className={styles.title}>Войти в аккаунт</h2>
                <form action="" className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
                    {authFormSchema.map((field) => {
                        return <AuthInput name={field.name} key={field.name} label={field.label} type={field.type}
                                          placeholder={field.placeholder} errors={errors} register={register}
                                          isDirty={isDirty}/>

                    })}
                    {errorMessage && <span className={styles.errorMessage}>{errorMessage}</span>}
                    <div className={styles.buttonsBlock}>
                        <button className={styles.button} type={'submit'} disabled={!isValid}>Войти</button>
                        <Link to={routes.registration} className={styles.registrationLink}>Зарегистрироваться</Link>
                    </div>
                    <div className={styles.bottomBlock}>
                        <SocialLoginOptions isLoginPage={true} />

                    </div>
                </form>
            </div>
        </div>
    );
};
