import React from 'react';
import styles from './NewsInitialItem.module.css'
import {routes} from "../../../../routes/routes";
import LinkWithArrow from "../../../../common/LinkWithArrow/LinkWithArrow";

const NewsInitialItem = ({type, title}) => {
    return (
        <div className={`${styles.wrapper} ${type === 'start' ? styles.backgroundStart : styles.backgroundEnd}`}>

            <div className={styles.container}>
                <div className={styles.titleLinkBlock}>
                    <span className={styles.title}>{title}</span>
                    <LinkWithArrow path={routes.news} title={'Читать все новости'}/>
                </div>
            </div>

        </div>
    );
};

export default NewsInitialItem;