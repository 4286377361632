import React from 'react';
import styles from './NewsItem.module.css'
import likesLogo from '../../../../assets/likes.svg'
import commentsLogo from '../../../../assets/comments.svg'
import {Link} from "react-router-dom";
import {routes} from "../../../../routes/routes";

const NewsItem = ({image, date, likeCounts, commentsCounts, newsTitle, newsText, id}) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.imageBlock}>
                <img className={styles.image} src={image} alt="image"/>
            </div>
            <div className={styles.newsBlock}>
                <div className={styles.newsBlockContainer}>
                    <div className={styles.dateCommentsBlock}>
                        <span className={styles.date}>{date}</span>
                        <div className={styles.commentsLikesBlock}>
                            <div className={styles.likes}>
                                <img className={styles.likesLogo} src={likesLogo} alt="logo"/>
                                <span>{likeCounts}</span>
                            </div>
                            <div className={styles.likes}>
                                <img className={styles.likesLogo} src={commentsLogo} alt="logo"/>
                                <span>{commentsCounts}</span>
                            </div>

                        </div>
                    </div>
                    <div className={styles.textBlock}>
                        <span className={styles.title}>{newsTitle}</span>
                        <span className={styles.text}>{newsText}</span>
                    </div>
                </div>
            </div>
            <div className={styles.linkBlock}>
                    <Link className={styles.link} to={`${routes.news}/${id}`}>Читать далее</Link>
            </div>
        </div>
    );
};

export default NewsItem;