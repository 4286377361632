import React from 'react';
import styles from './AuthInput.module.css';
const AuthInput = ({name, label, type, placeholder, errors, register, isDirty}) => {
    return (
        <div className={styles.formContainer}>
            <label className={styles.label}>{label}</label>
            <input type={type}
                   className={`${styles.input} ${isDirty && errors[name] ? styles.errorInput : ''}`}
                   id={name}
                   placeholder={placeholder}
                   {...register(name)}/>
            {errors[name] &&
                <span className={styles.error}>{errors[name].message}</span>}
        </div>
    );
};

export default AuthInput;