import React from 'react';
import styles from './VacancyInternItem.module.css'
import bullet from './../../../../assets/bullet.png'
import bookmark from './../../../../assets/bookmarGrey.svg'
import {Link} from "react-router-dom";
import {routes} from "../../../../routes/routes";

const VacancyInternItem = ({type, id, image, company, date, position, level, salary, location, workFormat}) => {
    const [selected, setSelected] = React.useState(false)
    const handleSelectVacancy = () => {
        setSelected(!selected)
    }
    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.mainBlock}>
                    <div className={styles.headerBlock}>
                        <div className={styles.companyBlock}>
                            <img className={styles.bulletImage} src={bullet} alt="image"/>
                            <span className={styles.company}>{company}</span>
                        </div>
                        <span className={`${styles.company} ${styles.date}`}>{date}</span>
                    </div>
                    <div className={styles.descriptionBlock}>
                        <div className={styles.leftBlock}>
                            <img onClick={handleSelectVacancy}
                                 className={`${styles.bookmark} ${selected ? styles.bookmarkSelected : styles.bookmarkUnSelected}`}
                                 src={bookmark} alt="bookmark"/>
                            <div className={styles.positionBlock}>
                                <span className={styles.position}>{position}</span>
                                <span className={styles.level}>{level}</span>
                            </div>
                        </div>
                        <div className={styles.rightBlock}>
                            <span className={`${styles.company} ${styles.level}`}>{salary}</span>
                            <span className={styles.location}>{location}</span>
                        </div>
                    </div>


                </div>
                <div className={styles.bottomBlock}>
                    <span className={styles.workFormat}>{workFormat}</span>
                    {type === 'vacancies' ?
                        <Link className={styles.linkButton} to={`${routes.jobs}/${id}`}>Подробнее</Link>
                        :
                        <Link className={styles.linkButton} to={`${routes.internships}/${id}`}>Подробнее</Link>

                    }
                </div>
            </div>
        </div>
    );
};

export default VacancyInternItem;