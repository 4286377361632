import React from 'react';
import { components } from "react-select";
import remove from "../../assets/cross-white.svg";

export const MultiValueRemove = (props) => {
  return (
    <>
      <components.MultiValueRemove {...props}>
        <img src={remove} alt="remove" />
      </components.MultiValueRemove>
    </>
  );
};