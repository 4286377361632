export const dateFormat = (date) => {
  const options = {
    day: "numeric",
    month: "long",
    hour: "numeric", 
    minute: "numeric",
  };
  const correctDate  = new Date(date).toLocaleDateString("ru-Latn", options);
  return correctDate;
}

export const sortByDate = (arr) => {
  const copy = JSON.parse(JSON.stringify(arr));
  copy.length && copy.sort((a, b) => {
    return Date.parse(b.time_of_public) - Date.parse(a.time_of_public)
  })
  return copy;
}

export const dateFormatForArticles = (date) => {
  const options = {
    day: "numeric",
    month: "long",
    year: "numeric"
  }
  const correctDate  = new Date(date).toLocaleDateString("ru-Latn", options).slice(0, -3)
  return correctDate
}