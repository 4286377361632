import * as yup from "yup";
import {
  confirmPasswordValidSchema,
  emailValidSchema,
  passwordValidSchema
} from "../../../../../../validation/validation";

export const regValidSchema = yup.object().shape({
  ...emailValidSchema.fields,
  ...passwordValidSchema.fields,
  ...confirmPasswordValidSchema.fields,
  conditions: yup
    .bool()
    .oneOf([true], "Необходимо принять условия пользовательского соглашения"),
}).required();
