import React from "react";
import { SocialLoginOptions } from "../../SocialLoginOptions/SocialLoginOptions";
import successImage from "../../../../assets/registration/success.png";
import { routes } from "../../../../routes/routes";

export const SuccessfulRegistration = ({ email }) => {
  return (
    <div className="p-6 bg-white my-auto mx-auto row-start-2 col-start-8 row-end-13 col-end-13">
      <img src={successImage} alt="Успешная регистрация" className="mx-auto block" />
<div className="flex flex-col text-center font-semibold text-[14px] leading-[18.2px] w-[320px]">
        <span className="mb-[25px]">
          На email {email} отправлено письмо. Перейдите по ссылке в письме для подтверждения Вашего email.
        </span>
        <a href={routes.registration} className="text-blue-600">
            Не пришло письмо? Отправить еще раз.
        </a>
</div>
      <SocialLoginOptions />
    </div>
  );
};
