import React, {useEffect, useRef, useState} from "react";
import epam from "../../assets/epam.png";
import Modal from "./Modal/Modal";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router";
import {getVacancies, getVacanciesScroll, getVacancyById, setChosenJob} from "../../store/reducers/VacanciesReducer";
import {useWindowSize} from "../../helpers/useWindowSize";
import {NavLink} from "react-router-dom";
import styles from './Jobs.module.css'
import {routes} from "../../routes/routes";
import FilterSelect from "../../common/FilterSelect/FilterSelect";

function Jobs() {

    const internships = [
        {
            id: "1",
            company: "Wargaming",
            logo: epam,
            rating: 5.0,
            position: "Backend Developer",
            published: "Сегодня 10.00",
            salary: "$800-$12K",
            location: "Minsk, BY",
            level: "Trainee",
            jobType: "Part-time, Office",
            companyDescription:
                "EPAM Systems — это место, где ты можешь стать частью команды, которая делает больше, чем просто проекты. Никаких ограничений, только твое желание двигаться вперед. Остальному мы научим.",
            duties: "Разработка клиентских приложений на основе JavaScript.",
            requirements: [
                "Практический опыт работы с HTML5/CSS3",
                "Понимание принципов ООП, MVC и других JS-паттернов",
                "Знание одного из менеджеров пакетов: Gulp, Grunt, WebPack",
                "Знание одного из фреймворков Angular, ReactJS+Redux, NodeJS.",
            ],
            desirable: [
                "Знание и опыт разработки JavaScript от 1 года",
                "Знание и опыт работы с CSS препроцессорами (SASS, LESS)",
                "Знания и опыт разработки Unit-тестов на JS-фреймворках",
                "Знания и опыт БЭМ методологии",
                "Знание английского языка на уровне Intermediate",
            ],
            conditions: [
                "График работы – гибкий, от 20 часов в неделю",
                "Офис или удаленная работа, по желанию",
                "Зарплата в зависимости от уровня ваших умений",
                "Работа по Scrum",
            ],
        },
        {
            id: "2",
            company: "Amazon",
            logo: epam,
            rating: 4.0,
            position: "Backend Developer",
            published: "Сегодня 10.00",
            salary: "$800-$12K",
            location: "Minsk, BY",
            level: "Trainee",
            jobType: "Part-time, Office",
            companyDescription:
                "EPAM Systems — это место, где ты можешь стать частью команды, которая делает больше, чем просто проекты. Никаких ограничений, только твое желание двигаться вперед. Остальному мы научим.",
            duties: "Разработка клиентских приложений на основе JavaScript.",
            requirements: [
                "Практический опыт работы с HTML5/CSS3",
                "Понимание принципов ООП, MVC и других JS-паттернов",
                "Знание одного из менеджеров пакетов: Gulp, Grunt, WebPack",
                "Знание одного из фреймворков Angular, ReactJS+Redux, NodeJS.",
            ],
            desirable: [
                "Знание и опыт разработки JavaScript от 1 года",
                "Знание и опыт работы с CSS препроцессорами (SASS, LESS)",
                "Знания и опыт разработки Unit-тестов на JS-фреймворках",
                "Знания и опыт БЭМ методологии",
                "Знание английского языка на уровне Intermediate",
            ],
            conditions: [
                "График работы – гибкий, от 20 часов в неделю",
                "Офис или удаленная работа, по желанию",
                "Зарплата в зависимости от уровня ваших умений",
                "Работа по Scrum",
            ],
        },
        {
            id: "3",
            company: "Google",
            logo: epam,
            rating: 5.0,
            position: "Backend Developer",
            published: "Сегодня 10.00",
            salary: "$800-$12K",
            location: "Minsk, BY",
            level: "Trainee",
            jobType: "Part-time, Office",
            companyDescription:
                "EPAM Systems — это место, где ты можешь стать частью команды, которая делает больше, чем просто проекты. Никаких ограничений, только твое желание двигаться вперед. Остальному мы научим.",
            duties: "Разработка клиентских приложений на основе JavaScript.",
            requirements: [
                "Практический опыт работы с HTML5/CSS3",
                "Понимание принципов ООП, MVC и других JS-паттернов",
                "Знание одного из менеджеров пакетов: Gulp, Grunt, WebPack",
                "Знание одного из фреймворков Angular, ReactJS+Redux, NodeJS.",
            ],
            desirable: [
                "Знание и опыт разработки JavaScript от 1 года",
                "Знание и опыт работы с CSS препроцессорами (SASS, LESS)",
                "Знания и опыт разработки Unit-тестов на JS-фреймворках",
                "Знания и опыт БЭМ методологии",
                "Знание английского языка на уровне Intermediate",
            ],
            conditions: [
                "График работы – гибкий, от 20 часов в неделю",
                "Офис или удаленная работа, по желанию",
                "Зарплата в зависимости от уровня ваших умений",
                "Работа по Scrum",
            ],
        },
        {
            id: "4",
            company: "Retarcorp",
            logo: epam,
            rating: 4.0,
            position: "Backend Developer",
            published: "Сегодня 10.00",
            salary: "$800-$12K",
            location: "Minsk, BY",
            level: "Trainee",
            jobType: "Part-time, Office",
            companyDescription:
                "EPAM Systems — это место, где ты можешь стать частью команды, которая делает больше, чем просто проекты. Никаких ограничений, только твое желание двигаться вперед. Остальному мы научим.",
            duties: "Разработка клиентских приложений на основе JavaScript.",
            requirements: [
                "Практический опыт работы с HTML5/CSS3",
                "Понимание принципов ООП, MVC и других JS-паттернов",
                "Знание одного из менеджеров пакетов: Gulp, Grunt, WebPack",
                "Знание одного из фреймворков Angular, ReactJS+Redux, NodeJS.",
            ],
            desirable: [
                "Знание и опыт разработки JavaScript от 1 года",
                "Знание и опыт работы с CSS препроцессорами (SASS, LESS)",
                "Знания и опыт разработки Unit-тестов на JS-фреймворках",
                "Знания и опыт БЭМ методологии",
                "Знание английского языка на уровне Intermediate",
            ],
            conditions: [
                "График работы – гибкий, от 20 часов в неделю",
                "Офис или удаленная работа, по желанию",
                "Зарплата в зависимости от уровня ваших умений",
                "Работа по Scrum",
            ],
        },
        {
            id: "5",
            company: "Wargaming",
            logo: epam,
            rating: 4.0,
            position: "Backend Developer",
            published: "Сегодня 10.00",
            salary: "$800-$12K",
            location: "Minsk, BY",
            level: "Trainee",
            jobType: "Part-time, Office",
            companyDescription:
                "EPAM Systems — это место, где ты можешь стать частью команды, которая делает больше, чем просто проекты. Никаких ограничений, только твое желание двигаться вперед. Остальному мы научим.",
            duties: "Разработка клиентских приложений на основе JavaScript.",
            requirements: [
                "Практический опыт работы с HTML5/CSS3",
                "Понимание принципов ООП, MVC и других JS-паттернов",
                "Знание одного из менеджеров пакетов: Gulp, Grunt, WebPack",
                "Знание одного из фреймворков Angular, ReactJS+Redux, NodeJS.",
            ],
            desirable: [
                "Знание и опыт разработки JavaScript от 1 года",
                "Знание и опыт работы с CSS препроцессорами (SASS, LESS)",
                "Знания и опыт разработки Unit-тестов на JS-фреймворках",
                "Знания и опыт БЭМ методологии",
                "Знание английского языка на уровне Intermediate",
            ],
            conditions: [
                "График работы – гибкий, от 20 часов в неделю",
                "Офис или удаленная работа, по желанию",
                "Зарплата в зависимости от уровня ваших умений",
                "Работа по Scrum",
            ],
        },
        {
            id: "6",
            company: "Wargaming",
            logo: epam,
            rating: 4.0,
            position: "Backend Developer",
            published: "Сегодня 10.00",
            salary: "$800-$12K",
            location: "Minsk, BY",
            level: "Trainee",
            jobType: "Part-time, Office",
            companyDescription:
                "EPAM Systems — это место, где ты можешь стать частью команды, которая делает больше, чем просто проекты. Никаких ограничений, только твое желание двигаться вперед. Остальному мы научим.",
            duties: "Разработка клиентских приложений на основе JavaScript.",
            requirements: [
                "Практический опыт работы с HTML5/CSS3",
                "Понимание принципов ООП, MVC и других JS-паттернов",
                "Знание одного из менеджеров пакетов: Gulp, Grunt, WebPack",
                "Знание одного из фреймворков Angular, ReactJS+Redux, NodeJS.",
            ],
            desirable: [
                "Знание и опыт разработки JavaScript от 1 года",
                "Знание и опыт работы с CSS препроцессорами (SASS, LESS)",
                "Знания и опыт разработки Unit-тестов на JS-фреймворках",
                "Знания и опыт БЭМ методологии",
                "Знание английского языка на уровне Intermediate",
            ],
            conditions: [
                "График работы – гибкий, от 20 часов в неделю",
                "Офис или удаленная работа, по желанию",
                "Зарплата в зависимости от уровня ваших умений",
                "Работа по Scrum",
            ],
        },
        {
            id: "7",
            company: "Wargaming",
            logo: epam,
            rating: 4.0,
            position: "Backend Developer",
            published: "Сегодня 10.00",
            salary: "$800-$12K",
            location: "Minsk, BY",
            level: "Trainee",
            jobType: "Part-time, Office",
            companyDescription:
                "EPAM Systems — это место, где ты можешь стать частью команды, которая делает больше, чем просто проекты. Никаких ограничений, только твое желание двигаться вперед. Остальному мы научим.",
            duties: "Разработка клиентских приложений на основе JavaScript.",
            requirements: [
                "Практический опыт работы с HTML5/CSS3",
                "Понимание принципов ООП, MVC и других JS-паттернов",
                "Знание одного из менеджеров пакетов: Gulp, Grunt, WebPack",
                "Знание одного из фреймворков Angular, ReactJS+Redux, NodeJS.",
            ],
            desirable: [
                "Знание и опыт разработки JavaScript от 1 года",
                "Знание и опыт работы с CSS препроцессорами (SASS, LESS)",
                "Знания и опыт разработки Unit-тестов на JS-фреймворках",
                "Знания и опыт БЭМ методологии",
                "Знание английского языка на уровне Intermediate",
            ],
            conditions: [
                "График работы – гибкий, от 20 часов в неделю",
                "Офис или удаленная работа, по желанию",
                "Зарплата в зависимости от уровня ваших умений",
                "Работа по Scrum",
            ],
        },
        {
            id: "8",
            company: "Wargaming",
            logo: epam,
            rating: 4.0,
            position: "Backend Developer",
            published: "Сегодня 10.00",
            salary: "$800-$12K",
            location: "Minsk, BY",
            level: "Trainee",
            jobType: "Part-time, Office",
            companyDescription:
                "EPAM Systems — это место, где ты можешь стать частью команды, которая делает больше, чем просто проекты. Никаких ограничений, только твое желание двигаться вперед. Остальному мы научим.",
            duties: "Разработка клиентских приложений на основе JavaScript.",
            requirements: [
                "Практический опыт работы с HTML5/CSS3",
                "Понимание принципов ООП, MVC и других JS-паттернов",
                "Знание одного из менеджеров пакетов: Gulp, Grunt, WebPack",
                "Знание одного из фреймворков Angular, ReactJS+Redux, NodeJS.",
            ],
            desirable: [
                "Знание и опыт разработки JavaScript от 1 года",
                "Знание и опыт работы с CSS препроцессорами (SASS, LESS)",
                "Знания и опыт разработки Unit-тестов на JS-фреймворках",
                "Знания и опыт БЭМ методологии",
                "Знание английского языка на уровне Intermediate",
            ],
            conditions: [
                "График работы – гибкий, от 20 часов в неделю",
                "Офис или удаленная работа, по желанию",
                "Зарплата в зависимости от уровня ваших умений",
                "Работа по Scrum",
            ],
        },
        {
            id: "9",
            company: "Wargaming",
            logo: epam,
            rating: 4.0,
            position: "Backend Developer",
            published: "Сегодня 10.00",
            salary: "$800-$12K",
            location: "Minsk, BY",
            level: "Trainee",
            jobType: "Part-time, Office",
            companyDescription:
                "EPAM Systems — это место, где ты можешь стать частью команды, которая делает больше, чем просто проекты. Никаких ограничений, только твое желание двигаться вперед. Остальному мы научим.",
            duties: "Разработка клиентских приложений на основе JavaScript.",
            requirements: [
                "Практический опыт работы с HTML5/CSS3",
                "Понимание принципов ООП, MVC и других JS-паттернов",
                "Знание одного из менеджеров пакетов: Gulp, Grunt, WebPack",
                "Знание одного из фреймворков Angular, ReactJS+Redux, NodeJS.",
            ],
            desirable: [
                "Знание и опыт разработки JavaScript от 1 года",
                "Знание и опыт работы с CSS препроцессорами (SASS, LESS)",
                "Знания и опыт разработки Unit-тестов на JS-фреймворках",
                "Знания и опыт БЭМ методологии",
                "Знание английского языка на уровне Intermediate",
            ],
            conditions: [
                "График работы – гибкий, от 20 часов в неделю",
                "Офис или удаленная работа, по желанию",
                "Зарплата в зависимости от уровня ваших умений",
                "Работа по Scrum",
            ],
        },
        {
            id: "10",
            company: "Wargaming",
            logo: epam,
            rating: 4.0,
            position: "Backend Developer",
            published: "Сегодня 10.00",
            salary: "$800-$12K",
            location: "Minsk, BY",
            level: "Trainee",
            jobType: "Part-time, Office",
            companyDescription:
                "EPAM Systems — это место, где ты можешь стать частью команды, которая делает больше, чем просто проекты. Никаких ограничений, только твое желание двигаться вперед. Остальному мы научим.",
            duties: "Разработка клиентских приложений на основе JavaScript.",
            requirements: [
                "Практический опыт работы с HTML5/CSS3",
                "Понимание принципов ООП, MVC и других JS-паттернов",
                "Знание одного из менеджеров пакетов: Gulp, Grunt, WebPack",
                "Знание одного из фреймворков Angular, ReactJS+Redux, NodeJS.",
            ],
            desirable: [
                "Знание и опыт разработки JavaScript от 1 года",
                "Знание и опыт работы с CSS препроцессорами (SASS, LESS)",
                "Знания и опыт разработки Unit-тестов на JS-фреймворках",
                "Знания и опыт БЭМ методологии",
                "Знание английского языка на уровне Intermediate",
            ],
            conditions: [
                "График работы – гибкий, от 20 часов в неделю",
                "Офис или удаленная работа, по желанию",
                "Зарплата в зависимости от уровня ваших умений",
                "Работа по Scrum",
            ],
        },
    ];

    //const [chosenJob, setChosenJob] = useState('');
    const [idVacancy, setIdVacancy] = useState(null);
    const location = useLocation();
    const {width: screenWidth} = useWindowSize();

// 	useEffect(() => {
//     const lastSegment = location.pathname.split('/').pop();
//     if (lastSegment && lastSegment !== 'jobs') {
//         setIdVacancy(lastSegment);
//     }
// }, [location.pathname]);

    useEffect(() => {
        const lastSegment = location.pathname.split('/').pop();
        setIdVacancy(lastSegment);
    }, [location.pathname]);

    const [isJob, setIsJob] = useState(true);
    const {
        allVacancies,
        isPending,
        chosenJob,
        filters,
        amount,
        lastVacancyID
    } = useSelector((state) => state.vacancies);
    const vacancyRef = useRef();
    const dispatch = useDispatch();
    const [bookmarked, setBookmarked] = useState(localStorage.getItem('bookmarked') ? JSON.parse(localStorage.getItem('bookmarked')) : []);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const isHidden = true;
    const refForScroll = useRef();

    const handleBookmarkClick = (id) => {
        const copyBookmarked = Object.assign([], bookmarked);
        if (copyBookmarked.includes(id)) {
            const index = copyBookmarked.indexOf(id);
            copyBookmarked.splice(index, 1);
        } else {
            copyBookmarked.push(id);
        }
        setBookmarked(() => copyBookmarked);
        localStorage.setItem('bookmarked', JSON.stringify(copyBookmarked));
    }

    const updateShowModal = (value) => {
        setShowModal(value);
    };

    // useEffect(() => {
    // 	!allVacancies.length && dispatch(getVacancies({lastVacancyID: null, filters: null}))
    // }, [])

    useEffect(() => {
        if (!allVacancies.length || allVacancies.length === 1) {
            dispatch(getVacancies({lastVacancyID: null, filters: null}));
        }
    }, [allVacancies.length, dispatch]);

    useEffect(() => {
        if (idVacancy === 'jobs' && allVacancies.length && window.innerWidth > 1023 && Object.keys(chosenJob).length === 0) {
            dispatch(setChosenJob({}))
            dispatch(getVacancyById(allVacancies[0].id))
        }
    }, [allVacancies, idVacancy, dispatch]);

    useEffect(() => {
        if (idVacancy !== null && idVacancy !== 'jobs') {
            dispatch(getVacancyById(idVacancy));
        }
    }, [dispatch, idVacancy]);


    const onClickVacancy = (jobs, id) => {
        dispatch(getVacancyById(id))
    };

    const scrollHandler = () => {
        if (
            (refForScroll.current.scrollHeight - refForScroll.current.scrollTop - refForScroll.current.clientHeight) <= 750 &&
            allVacancies.length < amount
        ) {
            if (filters) {
                dispatch(getVacanciesScroll({
                    lastVacancyID: null,
                    filters: {...filters, id: allVacancies[allVacancies.length - 1].id}
                }));
            } else {
                dispatch(getVacanciesScroll({
                    lastVacancyID: allVacancies.length ? allVacancies[allVacancies.length - 1].id : null,
                    filters: null
                }));
            }
        }
    }


    return (
        <div className={styles.wrapper}>
            <div className={styles.headerWrapper}>
                <div className={styles.headerContainer}>
                    <div className={styles.linksBlock}>
                        <NavLink className={({isActive, isPending}) =>
                            isActive ? styles.activeNavLink : isPending ? styles.pendingNavLink : styles.pendingNavLink}
                                 to={routes.jobsVacancies}>Работа</NavLink>
                        <NavLink className={({isActive, isPending}) =>
                            isActive ? styles.activeNavLink : isPending ? styles.pendingNavLink : styles.pendingNavLink}
                                 to={routes.jobsInternships}>Стажировка</NavLink>
                    </div>
                    <div className={styles.filtersBlock}>
                        <FilterSelect defaultValue={'по убыванию'} label={'Зарплата'}
                                      options={[{label: 'по убыванию', value: 'desc'}, {
                                          label: 'по возрастанию',
                                          value: 'asc'
                                      }]}/>
                        <FilterSelect defaultValue={'за последние 7 дней'} label={'Дата публикации'}
                                      options={[{label: 'за последние 7 дней', value: '7days'}, {
                                          label: 'за сегодня',
                                          value: 'today'
                                      }]}/>
                        <Modal showModal={showModal} updateShowModal={updateShowModal}/>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Jobs;
