import React from "react";
import styles from "./Telegram.module.css";
import telegram from './../../assets/vector.svg'
import Junior from '../../assets/cat_avatar_junior.png'
import BackEnd from '../../assets/cat_avatar_back-end.png'
import DevOps from '../../assets/cat_avatar_devops.png'
import Designers from '../../assets/cat_avatar_designers.png'
import MobileDev from '../../assets/cat_avatar_mobile-dev.png'
import HR from '../../assets/cat_avatar_hr.png'
import QA from '../../assets/cat_avatar_qa.png'
import Sales from '../../assets/cat_avatar_sales.png'
import Gamedev from '../../assets/cat_avatar_gamedev.png'
import PM_PrdM from '../../assets/cat_avatar_PM_PrdM.png'
import Frontend from '../../assets/cat_avatar_frontend.png'
import ITBisnesAnalitic from '../../assets/cat_avatar_IT_analitic.png'
import Marketologs from '../../assets/cat_avatar_marketologs.png'
import Aggregator from '../../assets/cat_avatar_aggregator.png'
import TelegramItem from "./TelegramItem/TelegramItem";

export const telegramChannels = [
    {
        id: 1,
        title: 'JUNIOR СПЕЦИАЛИСТЫ',
        image: Junior,
        path: "https://t.me/jobs_for_it_juniors"
    },
    {
        id: 2,
        title: 'Дизайнеры',
        image: Designers,
        path: "https://t.me/Jobs_for_designers"
    },
    {
        id: 3,
        title: 'PM и PrdM',
        image: PM_PrdM,
        path: "https://t.me/Jobs_aggregator_PM"
    },
    {
        id: 4,
        title: 'Back-end dev',
        image: BackEnd,
        path: "https://t.me/backend_dev_jobs_agregator"
    },
    {
        id: 5,
        title: 'DevOps',
        image: DevOps,
        path: "https://t.me/+QjwAiEylInNhMDEy"
    },
    {
        id: 6,
        title: 'Mobile dev',
        image: MobileDev,
        path: "https://t.me/+Zwfw9SzOojI3ZjVi"
    },
    {
        id: 7,
        title: 'IT HR',
        image: HR,
        path: "https://t.me/+-Jg3LAn5Wt45NmJi"
    },
    {
        id: 8,
        title: 'Manual & Auto QA',
        image: QA,
        path: "https://t.me/qa_job_agregator"
    },
    {
        id: 9,
        title: 'Продажи в сфере IT',
        image: Sales,
        path: "https://t.me/+R3w01li8sUk2ZTBi"
    },
    {
        id: 10,
        title: 'GameDev',
        description: 'разработчики, дизайнеры',
        image: Gamedev,
        path: "https://t.me/+q5GC-q4o6gYxMzFi"
    },
    {
        id: 11,
        title: 'Front-end dev',
        image: Frontend,
        path: "https://t.me/jobs_for_frontend"
    },
    {
        id: 12,
        title: 'IT Бизнес и аналитики',
        image: ITBisnesAnalitic,
        path: "https://t.me/BA_jobs_agregator"
    },
    {
        id: 13,
        title: 'Маркетологи',
        description: 'smm, targeting, creative, etc',
        image: Marketologs,
        path: "https://t.me/+bDDQhL-19ERmMjky"
    },
    {
        id: 14,
        title: 'IT jobs aggregator',
        image: Aggregator,
        path: "https://t.me/it_jobs_agregator"
    },
]

const Telegram = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.headerWrapper}>

                <p>Подписывайтесь на наши <span>Телеграм каналы</span><br/> c вакансиями для:</p>
                <img src={telegram} alt="telegram"/>
            </div>
            <div className={styles.container}>
                {telegramChannels.map((item) => {
                    return <TelegramItem key={item.id} discription={item.description} title={item.title}
                                         path={item.path} image={item.image}/>
                })}
            </div>
        </div>

    )
}

export default Telegram;
