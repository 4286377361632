import React from 'react';
import {Link} from "react-router-dom";
import logo from "../../../assets/logohead.svg";
import styles from './LogoComponent.module.css'

function LogoComponent() {
    return (
        <div className={styles.logoBlock}>
            <Link to={'/'}>
                <img src={logo} alt="Логотип"/>
            </Link>
        </div>
    );
}

export default LogoComponent;