import { configureStore } from "@reduxjs/toolkit";
import { registrationReducer } from "./reducers/RegistrationReducer";
import { vacanciesReducer } from "./reducers/VacanciesReducer";
import { enterItArticlesReducer } from "./reducers/EnterItArticlesReducer";
import {loginReducer} from "./reducers/LoginReducer";
import {userReducer} from "./reducers/UserReducer";
import {checkAuthSliceReducer} from "./reducers/CheckAuthReducer";

export const store = configureStore({
	reducer: {
		registration: registrationReducer,
		login: loginReducer,
		vacancies: vacanciesReducer,
		enterItArticles: enterItArticlesReducer,
		user: userReducer,
		auth: checkAuthSliceReducer
	},
});
