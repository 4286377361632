import { getCurrencyByName } from "../services/currenciesService.js";

const convertSalaryToUSD = (currency, value) => {
  let conversionForSlary = {}

  if (currency === 'BYN') {
    conversionForSlary = getCurrencyByName('USD')
    return +Math.floor(value / conversionForSlary.currencyOfficialRate)
  } else if (currency === 'RUB') {
    conversionForSlary = getCurrencyByName('RUB')
    const RUBtoBYN = (conversionForSlary.currencyOfficialRate / conversionForSlary.currencyScale) * value
    return +Math.floor(RUBtoBYN / getCurrencyByName('USD').currencyOfficialRate)
  } else if (value <= 0 ) return ''
  else return value
}

export default convertSalaryToUSD
