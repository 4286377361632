import React from 'react';
import styles from './TelegramItem.module.css';

const TelegramItem = ({image, path, title, discription}) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <img className={styles.logo} src={image} alt="logo"/>
                <div className={styles.titleBlock}>
                    <span className={`${styles.title} ${styles.titleOption}`}>{title}</span>
                    {discription && <span className={styles.title}>({discription})</span>}
                </div>
                <a href={path} className={styles.link} target={"_blank"}><span>Подписаться</span></a>
            </div>
        </div>
    );
};

export default TelegramItem;