import React from "react";
import { components } from "react-select";
import '../Checkbox/Checkbox.css';

export const CityOption = (props) => {
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  const newProps = { ...props, innerProps: rest };
  // console.log(newProps);
  return (
    <components.Option {...newProps}>
      <input
        id={newProps.data.id}
        type="checkbox"
        checked={newProps.isSelected}
        onChange={() => null}
        className="custom-checkbox"
      />{" "}
      <label className="min-w-fit truncate" htmlFor={newProps.data.id}>
        {newProps.data.active ? (
          <>
            <span className="whitespace-pre-wrap">
              {newProps.data.notActiveFirst && newProps.data.notActiveFirst}
            </span>
            <span className="whitespace-pre-wrap text-dark">
              {newProps.data.active}
            </span>
            <span className="whitespace-pre-wrap">
              {newProps.data.notActiveSecond}
            </span></>
        ) : newProps.label}
      </label>
      <p className=" ml-6 text-xs truncate text-lightGray">{props.data.countryName}</p>
  </components.Option>
  );
};